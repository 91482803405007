<div *ngIf="topicItem" class="top-content" [class.fade]="componentsState.fade">
  <div class="topic-info">
    <div *ngIf="!topicItem.visible" class="deactivated-msg">Deactivated. This Item will not show in report</div>
    <esg-header-with-guidance
      *ngIf="topicItem.description"
      [label]="topicItem.name ? topicItem.name : ''"
      [guidance]="topicItem.description"></esg-header-with-guidance>
    <h2 *ngIf="!topicItem.description">{{ topicItem.name || '' }}</h2>
    <div *ngIf="isNewIndicator" class="right-toolbar">
      <button
        (click)="handleCalculateIndicator()"
        (mouseenter)="state.setCalculationIsHovered(true)"
        (mouseleave)="state.setCalculationIsHovered(false)">
        <esg-cloud-update-icon *ngIf="!state.calculateIsHovered" color="#0071E3"></esg-cloud-update-icon>
        <esg-cloud-update-filled-icon *ngIf="state.calculateIsHovered" color="#0071E3"></esg-cloud-update-filled-icon>
        Update all indicators
      </button>
      <span class="last-update" [class.hidden]="!state.lastUpdated">
        {{ 'Last updated ' + (state.lastUpdated | date : 'dd. MMM yyyy HH:mm') }}
      </span>
    </div>
  </div>
</div>

<div *ngIf="showReferences" class="bottom-content" [class.fade]="componentsState.fade">
  <div class="reference-standards">
    <span class="header">
      <esg-framework-icon></esg-framework-icon>
      Reference Standards
    </span>
    <span class="standards">{{ formattedReferenceStandards }}</span>
  </div>
</div>

<div
  class="content-menu-observer"
  esgIntersectionObserver
  [root]="hostRef.nativeElement.parentNode"
  rootMargin="-56px 0px 0px 0px"
  (onIntersection)="handleStickyComponentMenu($event)"></div>
<esg-content
  [contentItems]="componentsState.componentsList"
  [stickyMenu]="stickyComponentsToolbar"
  [stickyTopMargin]="56"
  [loading]="componentsState.loading"
  [allowIndicators]="isNewIndicator"
  (onAddContent)="componentsState.handleOnAddComponent($event)"
  (onMoveContent)="componentsState.moveComponent($event)"
  (onDeleteContent)="componentsState.deleteComponent($event)"
  (onReorder)="handleOnReorder($event)"
  [onChange]="componentsState.onDataChangeComponent"></esg-content>
<esg-dot-flashing *ngIf="!topicItem" class="loader" label="We are loading content details"></esg-dot-flashing>
