import { Type } from '@angular/core';
import { IDynamicContentData } from './dynamic-content-data';
import {
  IPictureContentActions,
  IPictureContentData,
  IPictureContentStates,
  PictureComponent,
} from './content-item/picture/picture.component';
import {
  ITextAreaContentActions,
  ITextAreaContentData,
  ITextAreaContentStates,
  TextAreaComponent,
} from './content-item/text-area/text-area.component';
import {
  IImportFromExcelContentActions,
  IImportFromExcelContentData,
  IImportFromExcelContentStates,
  ImportFromExcelComponent,
} from './content-item/import-from-excel/import-from-excel.component';
import {
  IIndicatorContentActions,
  IIndicatorContentData,
  IIndicatorContentStates,
  IndicatorContentComponent,
} from './content-item/indicator-content/indicator-content.component';
import {
  TextDisclosureComponent,
  ITextDisclosureStates,
  ITextDisclosureData,
  ITextDisclosureActions,
} from './content-item/text-disclosure/text-disclosure.component';
import { ComponentType } from 'src/api-client/report-api.generated';

export type ContentActionsTypes =
  | IPictureContentActions
  | ITextAreaContentActions
  | IImportFromExcelContentActions
  | IIndicatorContentActions
  | ITextDisclosureActions;
export type ContentStatesTypes =
  | IPictureContentStates
  | ITextAreaContentStates
  | IImportFromExcelContentStates
  | IIndicatorContentStates
  | ITextDisclosureStates;
export type ContentDataTypes =
  | IPictureContentData
  | ITextAreaContentData
  | IImportFromExcelContentData
  | IIndicatorContentData
  | ITextDisclosureData;

export const ContentConfigurations: {
  [key: string]: any;
} = {
  [ComponentType.Text]: TextAreaComponent,
  [ComponentType.Picture]: PictureComponent,
  [ComponentType.ImportFromExcel]: ImportFromExcelComponent,
  [ComponentType.Indicator]: IndicatorContentComponent,
  [ComponentType.Narrative]: TextDisclosureComponent,
};

export function ComponentFactory(
  type: ComponentType
): Type<IDynamicContentData<ContentActionsTypes, ContentStatesTypes, ContentDataTypes>> {
  const component = ContentConfigurations[type];
  if (!component) {
    throw Error('lacking component configuration for compoent type: ' + type);
  }
  return component;
}
