<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading details"></esg-dot-flashing>

<ng-container *ngIf="!state.loading && state.indicator">
  <div class="content">
    <esg-description-and-reference
      [description]="state.indicatorDefinitonProperties?.description || ''"
      [reference]="state.indicatorDefinitonProperties?.standards || ''">
      <h2 class="title">
        {{ state.indicator.name }}
      </h2>
    </esg-description-and-reference>
  </div>
  <esg-tabs
    class="narrative-tabs"
    [activeTab]="state.activeTab"
    [tabs]="state.tabs"
    [tabIndent]="100"
    (onTabClick)="handleOnTabClick($event)"></esg-tabs>
</ng-container>
