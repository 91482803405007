import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ComponentType } from 'src/api-client/report-api.generated';

@Component({
  selector: 'esg-content-menu',
  templateUrl: './content-menu.component.html',
  styleUrls: ['./content-menu.component.scss'],
})
export class ContentMenuComponent implements OnInit {
  @Input() reordering: boolean = false;
  @Input() reorderHidden: boolean = false;
  @Input() allowIndicators: boolean = false;
  @Output() onAddContent = new EventEmitter<ComponentType>();
  @Output() onReorder = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  handleAddIndicator(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ComponentType.Indicator);
  }

  handleAddText(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ComponentType.Text);
  }

  handleAddImage(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ComponentType.Picture);
  }

  handleAddImportFromExcel(event: MouseEvent) {
    event.stopPropagation();
    this.onAddContent.emit(ComponentType.ImportFromExcel);
  }

  handleReorder() {
    this.onReorder.emit();
  }
}
