import { Component, OnInit } from '@angular/core';
import { DynamicContentBase, IContentCommonActions, IContentCommonStates } from '../../dynamic-content-data';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { IndicatorContentType } from '../indicator-content/indicator-content.component';

export interface ITextDisclosureActions extends IContentCommonActions {
  addIndicator: (indicatorId: string) => void;
  deleteIndicator: (indicatorId: string) => void;
  generateText?: (question: string) => Promise<string>;
  setTextDisclosure?: (disclosure?: string) => void;
}

export interface TextDisclosureValuesUi {
  indicatorId?: string;
  referenceStandards?: string;
  description?: string;
}

export interface ITextDisclosureStates extends IContentCommonStates {
  indicatorOptions: IGroupedOptions[];
  indicatorValue?: TextDisclosureValuesUi | undefined;
}

export interface ITextDisclosureData {
  textHtml: string;
  // indicatorId?: string;
  indicatorIds: string[];
  indicatorType?: IndicatorContentType;
}


@Component({
  selector: 'esg-text-disclosure',
  templateUrl: './text-disclosure.component.html',
  styleUrls: ['../content-item.component.scss', './text-disclosure.component.scss'],
})
export class TextDisclosureComponent
  extends DynamicContentBase<ITextDisclosureActions, ITextDisclosureStates, ITextDisclosureData>
  implements OnInit
{
  textAreaStyles = {
    fontSize: '18px',
    lineHeight: '28px',
    color: '#333333',
    border: 'none',
    padding: '20px 16px 4px 8px',
    opacity: '1',
  };

  textAreaStylesFocus = {
    fontSize: '18px',
    lineHeight: '28px',
    color: '#333333',
    border: '1px solid #0071E3',
    background: '#EDF5FD99',
    padding: '19px 15px 37px 7px',
    borderRadius: '4px',
    opacity: '1',
  };

  toolbarHidden = {
    display: 'none',
  };
  loading: boolean = false;

  fc = {
    content: new FormControl<string>(''),
    indicatorId: new FormControl<string | undefined>(undefined),
  };
  formgroup = new FormGroup(this.fc);

  isTextDisclosureVisible: boolean = false;

  get innerText() {
    return this.fc.content.value ? String(this.fc.content.value).replace(/<[^>]+>/gm, '') : '';
  }

  get readContent() {
    return this.data?.textHtml;
  }

  get toolbarStyles() {
    return {
      position: 'sticky',
      width: 'fit-content',
      marginTop: '-35px',
      marginLeft: '50%',
      transform: 'translate(-50%, 18px)',
      top: `${this.toolbarTop}px`,
      zIndex: '3',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
    };
  }

  get footerStyles() {
    return {
      width: '100%',
      marginTop: !this.active ? '0px' : '-35px',
      borderRadius: '4px',
      transform: 'translate(0, 0)',
      position: 'relative',
      zIndex: '3',
    };
  }

  get editorStyles() {
    const baseStyles = this.active ? { ...this.textAreaStylesFocus } : { ...this.textAreaStyles };
    if (!this.isVisibleInReport) {
      baseStyles.opacity = '0.4';
    }
    return baseStyles;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fc.content.setValue(this.data?.textHtml || '');
    this.fc.indicatorId.setValue(this.data?.indicatorIds[0]);
    this.formgroup.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(result => {
      if (this.formgroup.dirty && !this.formgroup.controls.content.disabled && !this.loading) {
        const updatedContent = {
          ...this.data,
          indicatorIds: this.data?.indicatorIds || [],
          textHtml: result.content || '',
        };

        this.onChange(
          this.contentId,
          this.isVisibleInReport,
          {
            indicatorOptions: this.states.indicatorOptions,
            indicatorValue: this.states.indicatorValue,
          },
          updatedContent
        );
        this.formgroup.markAsPristine();
      }
    });
  }

  handleComponentClick() {
    this.onSetActive.emit(true);
    this.isTextDisclosureVisible = false;
  }

  handleOutsideClick(target: HTMLElement) {
    if (!target.closest('.text-disclosure-dialog')) {
      this.onSetActive.emit(false);
      this.isTextDisclosureVisible = false;
      if (this.actions.setTextDisclosure) {
        this.actions.setTextDisclosure(undefined);
      }
    }
  }

  handleOnUpdateVisibility(visible: boolean) {
    this.onChange(
      this.contentId,
      visible,
      {
        indicatorOptions: this.states.indicatorOptions,
        indicatorValue: this.states.indicatorValue,
      },
      this.data
    );
  }

  handleOnGenerateText(prompt: string) {
    if (this.actions.generateText) {
      this.loading = true;
      this.formgroup.controls.content.setValue('<br><br><br><br>');
      this.actions
        .generateText(prompt)
        .then(res => {
          this.formgroup.controls.content.setValue('');
          this.loading = false;
          this.typingEffect(res);
        })
        .catch(() => {
          this.loading = false;
          this.formgroup.controls.content.setValue(
            '<p>ChatGPT is currently not responding. Please try again later.</p>'
          );
        });
    }
  }

  async typingEffect(text: string) {
    this.fc.content.disable();
    const words = text.split(' ');
    try {
      for (let i = 0; i < words.length - 1; i++) {
        this.fc.content.setValue(this.fc.content.value + words[i] + ' ');
        await new Promise(resolve => setTimeout(resolve, 50));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.fc.content.enable();
      this.fc.content.markAsDirty();
      this.fc.content.setValue(text);
    }
  }

  handleOnAddIndicator(option: IOption) {
    this.actions.addIndicator(option.value);
    this.fc.indicatorId.setValue(option.value);
  }

  handlerSetTextDisclosure($event: string) {
    if (this.actions.setTextDisclosure) {
      this.actions.setTextDisclosure($event);
    }
  }
  toggleModal(event: Event): void {
    event.preventDefault();
    this.isTextDisclosureVisible = !this.isTextDisclosureVisible;
    if (this.actions.setTextDisclosure) {
      this.actions.setTextDisclosure(
        this.isTextDisclosureVisible ? this.states.indicatorValue?.description : undefined
      );
    }
  }
}
