import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PictureComponent } from './content-item/picture/picture.component';
import { PictureToolbarComponent } from './content-item/picture/picture-toolbar/picture-toolbar.component';
import { TextAreaComponent } from './content-item/text-area/text-area.component';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { ContentTopbarComponent } from './content-item/content-topbar/content-topbar.component';
import { UiModule } from '../shared/ui/ui.module';
import { ImportFromExcelComponent } from './content-item/import-from-excel/import-from-excel.component';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { EmptyContentComponent } from './content-item/empty-content/empty-content.component';
import { ContentListComponent } from './content-list/content-list.component';
import { DynamicContentHostDirective } from './content-list/dynamic-content-item/ad-host.directive';
import { DynamicContentItemComponent } from './content-list/dynamic-content-item/dynamic-content-item.component';
import { ContentMenuComponent } from './content-menu/content-menu.component';
import { ContentComponent } from './content.component';
import { DirectiveModule } from '../shared/directives/directive.module';
import { IndicatorContentComponent } from './content-item/indicator-content/indicator-content.component';
import { IndicatorContentSetComponent } from './content-item/indicator-content/indicator-content-set/indicator-content-set.component';
import { IndicatorContentSubstrateDataComponent } from './content-item/indicator-content/indicator-content-substrate-data/indicator-content-substrate-data.component';
import { IndicatorContentToolbarComponent } from './content-item/indicator-content/indicator-content-toolbar/indicator-content-toolbar.component';
import { FormatUnitWithUnicodePipe } from '../shared/pipes/format-unit-with-unicode.pipe';
import { ContentContextMenuComponent } from './content-context-menu/content-context-menu.component';
import { TextDisclosureComponent } from './content-item/text-disclosure/text-disclosure.component';

@NgModule({
  declarations: [
    PictureComponent,
    PictureToolbarComponent,
    TextAreaComponent,
    ContentTopbarComponent,
    ImportFromExcelComponent,
    EmptyContentComponent,
    ContentComponent,
    ContentListComponent,
    DynamicContentHostDirective,
    DynamicContentItemComponent,
    ContentMenuComponent,
    IndicatorContentComponent,
    IndicatorContentSetComponent,
    IndicatorContentSubstrateDataComponent,
    IndicatorContentToolbarComponent,
    ContentContextMenuComponent,
    TextDisclosureComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    QuillModule,
    SharedComponentsModule,
    DirectiveModule,
    FormatUnitWithUnicodePipe,
  ],
  exports: [ContentComponent, ContentListComponent, ContentTopbarComponent, ContentContextMenuComponent],
})
export class ContentModule {}
