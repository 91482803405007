import { Component, Input, OnInit } from '@angular/core';
import { TimePeriod, TimePeriodType } from 'src/api-client/report-api.generated';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { SubstrateDataPreviewUi } from '../substrate-data-preview/substrate-data-preview.component';
import {
  IndicatorContentType,
  IndicatorPeriodLabelsUi,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';

export interface SectionContentViewModel {
  id: string;
  name: string;
  type: string;
  timePeriod?: TimePeriod;
  contents?: IContent[];
}

export interface IKeyfigures {
  currentValue: IKeyFigureValue;
  metadata: IKeyFiguresMetaData;
  previousValue: IKeyFigureValue;
  targetValue: IKeyFigureValue;
  referenceStandards?: string;
  substrateData?: SubstrateDataPreviewUi;
}

interface IKeyFiguresMetaData extends ISingleKeyFiguresMetaData {
  tableColumnsMetadata?: ISingleKeyFiguresMetaData[];
}

interface ISingleKeyFiguresMetaData {
  label?: string;
  type?: string;
  unit?: string;
  groupLabel?: string;
  isVisibleInReport?: boolean;
}

interface ISingleKeyFigureValue {
  type?: string;
  value?: string | number;
  unitDetails?: string;
}

interface IKeyFigureValue extends ISingleKeyFigureValue {
  tableRows?: ITableKeyFigureValue[];
}

export interface ITableKeyFigureValue {
  values?: ISingleKeyFigureValue[];
}

export interface IContent {
  textHtml: string;
  blobUrl: string;
  altText: string;
  title: string;
  tableConfig?: any;
  rows?: any;
  indicatorType?: IndicatorContentType;
  visibleColumns?: VisibleValueColumn[];
  keyFigures?: IKeyfigures[];
}

@Component({
  selector: 'esg-section-content-preview',
  templateUrl: './section-content-preview.component.html',
  styleUrls: ['./section-content-preview.component.scss'],
})
export class SectionContentPreviewComponent implements OnInit {
  @Input({ required: true }) section!: SectionContentViewModel;
  indicatorTypeEnum = IndicatorContentType;

  indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  references: string = '';

  constructor() {}

  get isCustomPeriod() {
    return this.section.timePeriod?.type === TimePeriodType.Custom;
  }

  ngOnInit(): void {
    this.indicatorPeriodLabels = this.section.timePeriod
      ? {
          targetLabel: 'Target ' + getTimeLabelFromPeriod(this.section.timePeriod, 1),
          currentLabel: getTimeLabelFromPeriod(this.section.timePeriod, 0),
          previousLabel: getTimeLabelFromPeriod(this.section.timePeriod, -1),
        }
      : {
          targetLabel: 'Target',
          currentLabel: 'Current',
          previousLabel: 'Previous',
        };
  }
  getKeyFigures(component: IContent) {
    if (!component.keyFigures) return null;
    return Array.from(
      new Set(
        component.keyFigures.flatMap(value => value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ').split(' | '))
      )
    ).join(' | ');
  }
}
