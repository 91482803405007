import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ComponentType } from 'src/api-client/report-api.generated';
import { IOption } from 'src/app/static-data/options';

@Component({
  selector: 'esg-topic-item-header',
  templateUrl: './topic-item-header.component.html',
  styleUrl: './topic-item-header.component.scss',
})
export class TopicItemHeaderComponent {
  @Input({ required: true }) topicItemId!: string;
  @Input() headerText: string = '';
  @Input() visible: boolean = false;
  @Input() collapsed: boolean = true;
  @Input() responsiblePerson?: IOption;
  @Input() responsiblePersonOptions: IOption[] = [];
  @Input() reorderMode: boolean = false;
  @Input() showEmptyText: boolean = false;
  @Input() showCollapseButton: boolean = false;
  @Output() onAddContent = new EventEmitter<ComponentType>();
  @Output() onEdit = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() onResponsiblePersonChange = new EventEmitter<IOption>();
  @Output() onUpdateVisibility = new EventEmitter<boolean>();
  @Output() onToggleCollapse = new EventEmitter();

  headerTextControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });
  active: boolean = false;
  responsiblePersonDropdownCollapsed: boolean = true;

  ngOnInit(): void {
    this.headerTextControl.setValue(this.headerText);
    this.headerTextControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
      this.onEdit.emit(value);
    });
  }

  handleFocus() {
    this.active = true;
  }

  handleOutsideClick() {
    this.active = false;
  }

  handleOnDelete() {
    this.onDelete.emit();
  }

  handleOnResponsiblePersonChange(responsiblePerson: IOption) {
    this.onResponsiblePersonChange.emit(responsiblePerson);
  }

  handleOnUpdateVisibility() {
    this.onUpdateVisibility.emit(!this.visible);
  }

  handleOnOpenResponsiblePersonDropdown(event: Event) {
    event.stopPropagation();
    if (!this.active) {
      this.active = true;
    } else {
      this.responsiblePersonDropdownCollapsed = !this.responsiblePersonDropdownCollapsed;
    }
  }

  handleOnToggleResponsiblePersonDropdown(collapse: boolean) {
    this.responsiblePersonDropdownCollapsed = collapse;
  }

  handleOnToggleCollapse() {
    this.onToggleCollapse.emit();
  }
}
