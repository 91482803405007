import { Component, Injectable } from '@angular/core';
import {
  ComponentType,
  CreateComponentCommandParams,
  DeleteComponentCommandParams,
  IndicatorDataValueType,
  MoveComponentCommandParams,
  NewIndicatorTopicItemVm,
  TopicItemType,
  UpdateComponentCommandParams,
  UpdateNewIndicatorTopicItemCommandParams,
} from 'src/api-client/report-api.generated';
import { DynamicContent, DynamicComponentFactory } from 'src/app/content/dynamic-content';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { DomSanitizer } from '@angular/platform-browser';
import { ChatAIApiService } from 'src/app/api-client/report-api/chat-ai-service';
import { ContentDataTypes, ContentStatesTypes } from 'src/app/content/content-configuration';
import { lastValueFrom } from 'rxjs';
import { IUploadedPhoto } from 'src/app/shared/ui/upload-picture/upload-picture.component';
import { IPictureContentData } from 'src/app/content/content-item/picture/picture.component';
import { IGroupedOptions } from 'src/app/static-data/options';
import { TopicItemApiService } from 'src/app/api-client/report-api/topic-item-api-service';
import {
  IIndicatorContentData,
  IIndicatorContentStates,
  IndicatorContentType,
  IndicatorContentValuesUi,
  IndicatorPeriodLabelsUi,
  VisibleValueColumn,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';
import { IndicatorContentSubstrateDataUi } from 'src/app/content/content-item/indicator-content/indicator-content-substrate-data/indicator-content-substrate-data.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ITextDisclosureActions,
  ITextDisclosureData,
  ITextDisclosureStates,
  TextDisclosureValuesUi,
} from 'src/app/content/content-item/text-disclosure/text-disclosure.component';

export interface DataValuesUi {
  indicatorId: string;
  currentValue: DataValueUi;
  metadata: DataValueMetaDataUi;
  previousValue: DataValueUi;
  targetValue: DataValueUi;
  substrateData?: IndicatorContentSubstrateDataUi;
  referenceStandards?: string;
}

interface DataSingleValueMetaDataUi {
  label?: string;
  type?: string;
  unit?: string;
  unitDescription?: string;
  groupLabel?: string;
  isVisibleInReport?: boolean;
}

interface DataValueMetaDataUi extends DataSingleValueMetaDataUi {
  tableColumnsMetadata?: DataSingleValueMetaDataUi[];
}

interface DataSingleValueUi {
  type?: string;
  value?: string | number;
  unitDetails?: string;
  errorMessage?: string;
}

export interface DataValueUi extends DataSingleValueUi {
  tableRows?: DataTableValueRowUi[];
}

export interface DataTableValueRowUi {
  values?: DataSingleValueUi[];
}

@Injectable()
export class TopicItemContentStateService {
  reportId: string = '';
  topicItemId: string = '';
  dynamicContent: DynamicContent[] = [];
  dataValues: DataValuesUi[] = [];
  removeContentId?: string;
  removeContentMessage = 'Once you delete this component\nthere is no turning back.';

  indicatorPeriodLabels: IndicatorPeriodLabelsUi = {
    targetLabel: 'Target',
    currentLabel: 'Current',
    previousLabel: 'Previous',
  };
  indicatorOptions: IGroupedOptions[] = [];
  indicatorSingleValueOptions: IGroupedOptions[] = [];

  isLoadingComponents: boolean = false;

  narrativeIndicatorOptions: IGroupedOptions[] = [];

  constructor(
    private topicItemApiService: TopicItemApiService,
    private appApiService: ReportAppApiService,
    private domSanitizer: DomSanitizer,
    private chatAIService: ChatAIApiService
  ) {}

  setIndicatorOptions(
    indicatorOptions: IGroupedOptions[],
    indicatorSingleValueOptions: IGroupedOptions[],
    narrativeIndicatorOptions: IGroupedOptions[]
  ) {
    this.indicatorOptions = indicatorOptions;
    this.narrativeIndicatorOptions = narrativeIndicatorOptions;
    this.indicatorSingleValueOptions = indicatorSingleValueOptions;
  }

  setIndicatorPeriodLabels(indicatorPeriodLabels: IndicatorPeriodLabelsUi) {
    this.indicatorPeriodLabels = indicatorPeriodLabels;
  }

  async getTopicItemComponents(reportId: string, topicItemId: string) {
    this.reportId = reportId;
    this.topicItemId = topicItemId;
    this.dynamicContent = [];
    this.dataValues = [];
    this.isLoadingComponents = true;

    this.topicItemApiService.getTopicItemDetails(topicItemId).subscribe(result => {
      if (result instanceof NewIndicatorTopicItemVm) {
        this.dataValues = result.dataValues.map(value => ({
          indicatorId: value.indicatorId,
          currentValue: value.currentValue as DataValueUi,
          metadata: value.metadata,
          previousValue: value.previousValue as DataValueUi,
          targetValue: value.targetValue as DataValueUi,
          substrateData: value.substrateData,
          referenceStandards: value.referenceStandards,
          description: value.description,
        }));

        const dynamicComponentFactory = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
          generateText: this.handleGetBotAnswer,
          addIndicator: this.handleAddIndicatorToSet,
          deleteIndicator: this.handleRemoveIndicatorFromSet,
          addTextDisclosure: this.handleAddTextDisclosureComponent,
          setTextDisclosure: this.handleOnSetTextDisclosure,
        });
        result.components.map(c => {
          let dynamicComponent;
          const isVisibleInReport = result.visible ? c.isVisibleInReport || false : false;
          if (c.type === ComponentType.Indicator) {
            const content = c.content ? JSON.parse(c.content) : undefined;
            const indicatorIds = content?.indicatorIds || [];
            const indicatorValues = this.getOrderedIndicatorValues(this.dataValues || [], indicatorIds);
            dynamicComponent = dynamicComponentFactory[c.type](
              c.id,
              isVisibleInReport,
              this.indicatorPeriodLabels,
              c.content ? this.getUnusedIndicatorOptions(indicatorIds) : this.indicatorOptions,
              indicatorValues,
              c.content
            );
          } else if (c.type === ComponentType.Narrative) {
            const content = c.content ? JSON.parse(c.content) : undefined;
            const indicatorIds = content?.indicatorIds || [];
            const indicatorValue = this.getTextDisclosureValues(this.dataValues || [], indicatorIds[0]);
            dynamicComponent = dynamicComponentFactory[c.type](
              c.id,
              isVisibleInReport,
              this.narrativeIndicatorOptions,
              indicatorValue,
              c.content
            );
          } else {
            dynamicComponent = dynamicComponentFactory[c.type](c.id, isVisibleInReport, c.content);
          }

          this.dynamicContent.push(dynamicComponent);
        });
      }
      this.isLoadingComponents = false;
    });
  }

  onDataChangeComponent = (
    contentId: string,
    isVisibleInReport: boolean,
    states: ContentStatesTypes,
    data?: ContentDataTypes,
    componentType?: ComponentType | undefined
  ) => {
    const component = this.dynamicContent.find(c => c.contentId === contentId);

    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }

    var updatedComponent: DynamicContent = {
      ...component,
      isVisibleInReport: isVisibleInReport,
      states: states,
      data,
    };

    if (componentType != undefined) {
      updatedComponent.contentType = componentType;
      if (componentType == ComponentType.Narrative) {
        (updatedComponent.actions as ITextDisclosureActions).generateText = this.handleGetBotAnswer;
        (updatedComponent.actions as ITextDisclosureActions).setTextDisclosure = this.handleOnSetTextDisclosure;
      }
    }
    this.dynamicContent = this.dynamicContent.map(c => (c.contentId !== component.contentId ? c : updatedComponent));

    this.topicItemApiService
      .updateComponent(
        new UpdateComponentCommandParams({
          componentId: component.contentId,
          topicItemId: this.topicItemId || '',
          title: '',
          isVisibleInReport: isVisibleInReport,
          content: data ? JSON.stringify(data) : '',
          componentType: componentType,
        })
      )
      .subscribe(result => {
        console.log('updated successfully!');
      });
  };

  async uploadPhoto(file: File) {
    const photo$ = this.appApiService.uploadImage({
      data: file,
      fileName: file.name,
    });
    return await lastValueFrom(photo$);
  }

  getUploadPhoto = (blobName: string): IUploadedPhoto => {
    var blobUrl = this.appApiService.getBlobUrl(blobName);
    var safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(blobUrl);
    return {
      url: safeUrl,
      fileName: blobName,
    };
  };

  handleUploadPhoto = (componentId: string) => async (file?: File) => {
    const component = this.dynamicContent.find(c => c.contentId === componentId);

    if (!component) {
      throw Error(`Comonent with id: ${componentId} doesn't exists`);
    }

    if (file) {
      const result = await this.uploadPhoto(file);
      const uploadedPhoto = this.getUploadPhoto(result.blobName || '');

      const updatedContent: IPictureContentData = {
        altText: '',
        blobName: result.blobName || '',
      };

      this.onDataChangeComponent(
        componentId,
        component.isVisibleInReport,
        {
          ...component?.states,
          uploadedPhoto: uploadedPhoto,
        },
        updatedContent
      );
    } else {
      this.onDataChangeComponent(
        componentId,
        component.isVisibleInReport,
        {
          ...component?.states,
          uploadedPhoto: undefined,
        },
        undefined
      );
    }
  };

  handleGetBotAnswer = async (question: string): Promise<string> => {
    return (await this.chatAIService.getBotAnswer(question)).answer;
  };

  async handleTopicItemNameChange(name: string) {
    const result = await this.topicItemApiService.updateTopicItem(
      new UpdateNewIndicatorTopicItemCommandParams({
        topicItemId: this.topicItemId,
        name: name,
        type: TopicItemType.NewIndicator,
        indicatorIds: this.dataValues.map(v => v.indicatorId),
      })
    );
    if (result?.success) {
      return name;
    } else {
      return null;
    }
  }

  handleOnAddComponent(type: ComponentType) {
    switch (type) {
      case ComponentType.Indicator:
        this.addEmptyIndicatorComponent();
        break;
      case ComponentType.Text:
        this.addTextComponent();
        break;
      case ComponentType.Picture:
        this.addImageComponent();
        break;
      case ComponentType.ImportFromExcel:
        this.addImportFromExcelComponent();
        break;
      case ComponentType.Narrative:
        this.addTextDisclosureComponent();
        break;
      default:
        break;
    }
  }

  addEmptyIndicatorComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Indicator,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        const IndicatorComponent = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
          generateText: this.handleGetBotAnswer,
          addIndicator: this.handleAddIndicatorToSet,
          deleteIndicator: this.handleRemoveIndicatorFromSet,
        })[ComponentType.Indicator](
          result.result?.id || '',
          true,
          this.indicatorPeriodLabels,
          this.indicatorOptions,
          [],
          JSON.stringify({
            visibleColumns: [
              VisibleValueColumn.PreviousValue,
              VisibleValueColumn.CurrentValue,
              VisibleValueColumn.TargetValue,
            ],
          })
        );
        this.dynamicContent = [...this.dynamicContent, IndicatorComponent];
      });
  }

  addTextDisclosureComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Narrative,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        const textDisclosureComponent = DynamicComponentFactory({
          uploadPhoto: this.handleUploadPhoto,
          getUploadedPhoto: this.getUploadPhoto,
          generateText: this.handleGetBotAnswer,
          addTextDisclosure: this.handleAddTextDisclosureComponent,
          setTextDisclosure: this.handleOnSetTextDisclosure,
          deleteIndicator: this.handleRemoveIndicatorFromSet,
        })[ComponentType.Narrative](
          result.result?.id || '',
          true,
          this.narrativeIndicatorOptions,
          undefined,
          undefined
        );
        this.dynamicContent = [...this.dynamicContent, textDisclosureComponent];
      });
  }

  addTextComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Text,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        this.dynamicContent = [
          ...this.dynamicContent,
          new DynamicContent(
            result.result?.id || '',
            ComponentType.Text,
            true,
            {
              generateText: this.handleGetBotAnswer,
            },
            {},
            undefined
          ),
        ];
      });
  }

  addImageComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.Picture,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        this.dynamicContent = [
          ...this.dynamicContent,
          new DynamicContent(
            result.result?.id || '',
            ComponentType.Picture,
            true,
            {
              uploadPhoto: this.handleUploadPhoto(result.result?.id || ''),
            },
            {},
            undefined
          ),
        ];
      });
  }

  addImportFromExcelComponent() {
    this.topicItemApiService
      .addComponent(
        new CreateComponentCommandParams({
          topicItemId: this.topicItemId || '',
          reportId: this.reportId || '',
          type: ComponentType.ImportFromExcel,
          title: '',
          isVisibleInReport: true,
        })
      )
      .subscribe(result => {
        this.dynamicContent = [
          ...this.dynamicContent,
          new DynamicContent(result.result?.id || '', ComponentType.ImportFromExcel, true, {}, {}, undefined),
        ];
      });
  }

  moveComponent(event: CdkDragDrop<any[]>) {
    const newList = [...this.dynamicContent];
    const prevList = [...this.dynamicContent];
    moveItemInArray(newList, event.previousIndex, event.currentIndex);
    this.dynamicContent = [...newList];

    this.topicItemApiService
      .moveComponent(
        new MoveComponentCommandParams({
          topicItemId: this.topicItemId || '',
          componentId: event.item.element.nativeElement.id,
          prevIndex: event.previousIndex,
          newIndex: event.currentIndex,
        })
      )
      .subscribe(
        result => {},
        error => {
          this.dynamicContent = [...prevList];
        }
      );
  }

  handleOnInitateDeleteComponent(removeContentId: string) {
    this.removeContentId = removeContentId;
  }

  handleOnDeleteComponentCancel() {
    this.removeContentId = undefined;
  }

  async deleteComponent() {
    const component = this.dynamicContent.find(comp => comp.contentId === this.removeContentId);
    if (!component) {
      throw Error(`Component with id: ${this.removeContentId} doesn't exist`);
    }
    if (!this.removeContentId) return;

    const contentId = this.removeContentId;

    if (component.contentType === ComponentType.Indicator) {
      const response = await this.topicItemApiService.removeIndicatorComponent(this.topicItemId || '', contentId);
      if (!response.success) return;
    }
    this.topicItemApiService
      .removeComponent(
        new DeleteComponentCommandParams({ topicItemId: this.topicItemId || '', componentId: contentId })
      )
      .subscribe(result => {
        if (result.success) {
          const newList = [...this.dynamicContent];
          newList.splice(
            this.dynamicContent.findIndex(comp => comp.contentId === contentId),
            1
          );
          this.dynamicContent = [...newList];
        }
      });
    this.removeContentId = undefined;
  }

  handleAddIndicatorToSet = (contentId: string) => async (indicatorId: string) => {
    const component = this.dynamicContent.find(c => c.contentId === contentId);
    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }
    if (component.contentType !== ComponentType.Indicator && component.contentType !== ComponentType.Narrative) return;

    const response = await this.topicItemApiService.addIndicatorToComponent(
      this.topicItemId || '',
      contentId,
      indicatorId
    );
    const states = component.states as IIndicatorContentStates;
    const data = component.data as IIndicatorContentData;
    const indicator = response.result as IndicatorContentValuesUi;

    if (indicator.metadata.type === IndicatorDataValueType.Numeric) {
      if (data?.indicatorIds?.length) {
        this.onDataChangeComponent(
          contentId,
          component.isVisibleInReport,
          {
            ...component.states,
            indicatorValues: [...states.indicatorValues, indicator],
            indicatorOptions: this.getUnusedIndicatorOptions([...data.indicatorIds, indicatorId]),
          },
          {
            ...data,
            indicatorIds: [...data.indicatorIds, indicatorId],
          }
        );
      } else {
        this.onDataChangeComponent(
          contentId,
          component.isVisibleInReport,
          {
            ...component.states,
            indicatorValues: [indicator],
            indicatorOptions: this.getUnusedIndicatorOptions([indicatorId]),
          },
          {
            ...data,
            indicatorType: IndicatorContentType.IndicatorSet,
            visibleColumns: [
              VisibleValueColumn.TargetValue,
              VisibleValueColumn.CurrentValue,
              VisibleValueColumn.PreviousValue,
            ],
            indicatorIds: [indicatorId],
          }
        );
      }
    } else if (indicator.metadata.type === IndicatorDataValueType.Table) {
      this.onDataChangeComponent(
        contentId,
        component.isVisibleInReport,
        { ...component.states, indicatorValues: [indicator] },
        {
          ...data,
          indicatorType: IndicatorContentType.IndicatorTableValue,
          visibleColumns: [VisibleValueColumn.CurrentValue, VisibleValueColumn.PreviousValue],
          indicatorIds: [indicatorId],
        }
      );
    } else if (indicator.metadata.type === IndicatorDataValueType.Narrative) {
      const tdStates = component.states as ITextDisclosureStates;
      const tdData = component.data as ITextDisclosureData;
      const tdIndicator = response.result as TextDisclosureValuesUi;
      tdStates.indicatorValue = indicator;
      this.onDataChangeComponent(
        contentId,
        component.isVisibleInReport,
        {
          ...component.states,
          indicatorValue: tdIndicator,
          indicatorOptions: this.narrativeIndicatorOptions,
        },
        {
          ...tdData,
          indicatorType: IndicatorContentType.Narrative,
          indicatorIds: [indicatorId],
        },
        ComponentType.Narrative
      );
    }
  };

  handleRemoveIndicatorFromSet = (contentId: string) => async (indicatorId: string) => {
    const component = this.dynamicContent.find(c => c.contentId === contentId);
    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exist`);
    }
    if (component.contentType !== ComponentType.Indicator) return;

    const response = await this.topicItemApiService.removeIndicatorFromComponent(
      this.topicItemId || '',
      contentId,
      indicatorId
    );

    if (response.success) {
      const states = component.states as IIndicatorContentStates;
      const indicatorRows = states.indicatorValues || [];
      const newIndicatorRows = indicatorRows.filter(i => i.indicatorId !== indicatorId);
      const contentData = component.data as IIndicatorContentData;
      const newIndicatorContentData = {
        ...contentData,
        indicatorIds: (contentData.indicatorIds || []).filter(id => id !== indicatorId),
      };
      this.onDataChangeComponent(
        contentId,
        component.isVisibleInReport,
        {
          ...component.states,
          indicatorValues: newIndicatorRows,
        },
        newIndicatorContentData
      );
    }
  };

  getUnusedIndicatorOptions(indicatorIds: string[]) {
    return this.indicatorSingleValueOptions.map(group => {
      return {
        label: group.label,
        options: group.options.filter(option => !indicatorIds.includes(option.value)),
      };
    });
  }

  getOrderedIndicatorValues(
    indicatorValues: IndicatorContentValuesUi[],
    indicatorIds: string[]
  ): IndicatorContentValuesUi[] {
    return indicatorIds.map(id => indicatorValues.find(value => value.indicatorId === id)!).filter(Boolean);
  }

  handleOnSetTextDisclosure?: (disclosure?: string | undefined) => void;

  handleAddTextDisclosureComponent = (contentId: string) => async (indicatorId: string) => {
    const component = this.dynamicContent.find(c => c.contentId === contentId);
    if (!component) {
      throw Error(`Component with id: ${contentId} doesn't exists`);
    }
    if (component.contentType !== ComponentType.Narrative) return;

    const response = await this.topicItemApiService.addIndicatorToComponent(
      this.topicItemId || '',
      contentId,
      indicatorId
    );
    const states = component.states as ITextDisclosureStates;
    const data = component.data as ITextDisclosureData;
    const indicator = response.result as TextDisclosureValuesUi;
    states.indicatorValue = indicator;

    this.onDataChangeComponent(
      contentId,
      component.isVisibleInReport,
      {
        ...component.states,
        indicatorValue: indicator,
        indicatorOptions: this.narrativeIndicatorOptions,
      },
      {
        ...data,
        indicatorIds: [indicatorId],
        indicatorType: IndicatorContentType.Narrative,
      }
    );
  };

  getTextDisclosureOptions(indicatorIds: string[]) {
    return this.narrativeIndicatorOptions.map(group => {
      return {
        label: group.label,
        options: group.options.filter(option => !indicatorIds.includes(option.value)),
      };
    });
  }

  getTextDisclosureValues(
    indicatorValues: IndicatorContentValuesUi[],
    indicatorIds: string
  ): IndicatorContentValuesUi | undefined {
    return indicatorValues.find(value => value.indicatorId === indicatorIds);
  }
}
