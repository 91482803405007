import { Component, Input } from '@angular/core';
import { NarrativeDetailsStateService } from './narrative-details-state.service';

@Component({
  selector: 'esg-narrative-details',
  templateUrl: './narrative-details.component.html',
  styleUrl: './narrative-details.component.scss',
  providers: [NarrativeDetailsStateService],
})
export class NarrativeDetailsComponent {
  @Input({ required: true }) indicatorId!: string;

  constructor(public state: NarrativeDetailsStateService) {}

  handleOnTabClick(tabValue: string) {
    this.state.setActiveTab(tabValue);
  }

  ngOnInit(): void {
    this.state.init(this.indicatorId);
  }
}
