<div class="footer">
  <div class="frameworkReference" *ngIf="indicatorValue">
    <span class="reference-label">Disclosure Reference:&nbsp;</span>
    <span class="">{{ references }}</span>
  </div>
  <div class="description" (click)="toggleModal($event)" *ngIf="indicatorValue; else indicatorselector">
    @if (active) {
    <esg-info-icon></esg-info-icon>
    <a href="#">
      {{ isTextDisclosureVisible ? 'Close description' : 'Open description' }}
    </a>
    }
  </div>
  <ng-template #indicatorselector>
    @if (active) {
    <div class="empty-active-container">
      <esg-select
        [customDropdown]="true"
        [groupedOptions]="indicatorOptions"
        [isCollapsed]="indicatorSelectCollapsed"
        (onChange)="onAddIndicator.emit($event)"
        (onToggleCollapse)="handleOnIndicatorSelectCollapse($event)">
        <esg-add-button
          label="Select narrative disclosure"
          (onClick)="handleOnIndicatorSelectClick($event)"></esg-add-button>
      </esg-select>
    </div>
    }
  </ng-template>
</div>
