<esg-dot-flashing *ngIf="state.loading" class="loader" label="Loading indicators"></esg-dot-flashing>

<ng-container *ngIf="!state.loading">
  <div class="top-section">
    <h2>{{ state.currentCategory || 'Indicators' }}</h2>
    <div class="all-calculation-details">
      <button class="update-button" (click)="state.updateAllIndicators()" [disabled]="state.updatingAllIndicators">
        <ng-container *ngIf="state.updatingAllIndicators">
          <esg-calculation-icon></esg-calculation-icon>
          Updating Indicators...
          <span class="update-progress">{{ state.updatingAllIndicatorsProgress }}</span>
        </ng-container>
        <ng-container *ngIf="!state.updatingAllIndicators && !state.calculationUpdateIsNeeded">
          <esg-check-circle-icon class="status-icon"></esg-check-circle-icon>
          Indicators Up to Date
        </ng-container>
        <ng-container *ngIf="!state.updatingAllIndicators && state.calculationUpdateIsNeeded">
          <esg-warning-filled-icon class="status-icon"></esg-warning-filled-icon>
          Indicators Calculation Update Needed
        </ng-container>
      </button>
      <span class="update-date">
        Last update:
        {{ state.indicatorsLastCalculated ? (state.indicatorsLastCalculated | date : 'dd. MMM yyyy, HH:mm:ss') : '' }}
      </span>
    </div>
  </div>
  <table>
    <thead>
      <tr>
        <th>INDICATOR</th>
        <th>LAST CALCULATED</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let indicator of state.indicators; let i = index; trackBy: trackByIndicator">
        <tr
          *ngIf="indicator.category === state.currentCategory || !state.currentCategory"
          [class.active]="state.selectedIndicator?.id === indicator.id"
          (click)="handleIndicatorClick(indicator.id)">
          <td>
            {{ indicator.name }}
          </td>
          <td>
            <div class="indicator-status">
              <esg-tooltip
                *ngIf="indicator.valueStatus === state.valueStatusEnum.OutOfDate"
                class="status-warning-tooltip"
                text="Please click, to update individual indicator"
                position="top"
                variant="dark"
                [underline]="false">
                <esg-warning-filled-icon class="status-icon"></esg-warning-filled-icon>
              </esg-tooltip>
              <esg-tooltip
                *ngIf="indicator.valueStatus === state.valueStatusEnum.WithErrors"
                class="status-warning-tooltip"
                [text]="
                  indicator.errorMessage
                    ? indicator.errorMessage
                    : 'There is an error in the calculation, please check the connected data'
                "
                position="top"
                variant="dark"
                [underline]="false">
                <esg-error-icon class="status-icon"></esg-error-icon>
              </esg-tooltip>
              <esg-check-circle-icon
                *ngIf="indicator.valueStatus === state.valueStatusEnum.UpToDate"
                class="status-icon"></esg-check-circle-icon>
              <esg-calculation-icon
                *ngIf="indicator.valueStatus === state.valueStatusEnum.CalculationInProgress"
                class="status-icon"></esg-calculation-icon>
              <span>{{ indicator.lastCalculated | date : 'dd.MMM' }}&nbsp;</span>
              <span class="italic">{{ indicator.lastCalculated | date : 'HH:mm' }}</span>
            </div>
          </td>
          <td>
            <esg-table-button label="Details" (onClick)="handleIndicatorClick(indicator.id)"></esg-table-button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<esg-details-dialog
  [open]="isIndicatorDetailsOpen"
  (onClose)="closeIndicatorDetails()"
  [options]="state.detailsMenuProvider()">
  <div action class="indicator-calculation-details">
    <button
      *ngIf="state.selectedIndicator && state.selectedIndicator.valueType !== state.valueTypeEnum.Narrative"
      class="update-button"
      (click)="state.updateSelectedIndicator()"
      [disabled]="state.updatingSelectedIndicator">
      <ng-container
        *ngIf="
          state.updatingSelectedIndicator ||
          state.selectedIndicator?.valueStatus === state.valueStatusEnum.CalculationInProgress
        ">
        <esg-calculation-icon></esg-calculation-icon>
        {{ state.updatingSelectedIndicator ? 'Updating Indicator...' : 'Indicator Calculation in Progress' }}
      </ng-container>
      <ng-container
        *ngIf="
          !state.updatingSelectedIndicator && state.selectedIndicator?.valueStatus === state.valueStatusEnum.UpToDate
        ">
        <esg-check-circle-icon class="status-icon"></esg-check-circle-icon>
        Indicator Up to Date
      </ng-container>
      <ng-container
        *ngIf="
          !state.updatingAllIndicators && state.selectedIndicator?.valueStatus === state.valueStatusEnum.OutOfDate
        ">
        <esg-warning-filled-icon class="status-icon"></esg-warning-filled-icon>
        Indicator Calculation Update Needed
      </ng-container>
      <ng-container
        *ngIf="
          !state.updatingAllIndicators && state.selectedIndicator?.valueStatus === state.valueStatusEnum.WithErrors
        ">
        <esg-tooltip
          class="status-warning-tooltip"
          [text]="state.selectedIndicator.errorMessage || ''"
          position="bottom"
          variant="dark"
          [underline]="false">
          <esg-error-icon class="status-icon"></esg-error-icon>
        </esg-tooltip>
        Indicator Calculation Caused Errors
      </ng-container>
    </button>
    <span
      class="update-date"
      *ngIf="state.selectedIndicator && state.selectedIndicator.valueType !== state.valueTypeEnum.Narrative">
      Last update:
      {{
        state.selectedIndicator && state.selectedIndicator.lastCalculated
          ? (state.selectedIndicator.lastCalculated | date : 'dd. MMM yyyy, HH:mm:ss')
          : ''
      }}
    </span>
  </div>
  <esg-indicator-details
    *ngIf="state.selectedIndicator && state.selectedIndicator.valueType !== state.valueTypeEnum.Narrative"
    [indicatorId]="state.selectedIndicator.id"
    [organizationCurrency]="state.organizationCurrency"
    [sectorTypes]="state.sectorTypes"
    [updatingCalculation]="state.updatingSelectedIndicator"></esg-indicator-details>
  <esg-narrative-details
    *ngIf="state.selectedIndicator && state.selectedIndicator.valueType === state.valueTypeEnum.Narrative"
    [indicatorId]="state.selectedIndicator.id"></esg-narrative-details>
</esg-details-dialog>
<esg-confirm-dialog
  *ngIf="state.showRemoveDialog && state.selectedIndicator"
  [message]="
    'Once you remove this indicator,\n you delete all the data attached to it,\n and there is no turning back.'
  "
  cancelText="No, keep it"
  submitText="Remove"
  (onCancel)="state.handleCloseRemoveDialog()"
  (onSubmit)="state.removeIndicator()"></esg-confirm-dialog>
<esg-dialog
  *ngIf="state.showNotPossibleToRemoveDialog && state.selectedIndicator"
  class="auto"
  [showCloseBtn]="true"
  (onClose)="state.handleCloseNotPossibleToRemoveDialog()">
  <div [ngClass]="['content', 'error', 'reportInUsed']">
    <div class="title">
      <div class="warning-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path
            d="M1.31746 16H16.6726C17.0167 16 17.288 15.8821 17.4865 15.6462C17.6917 15.4171 17.7942 15.1476 17.7942 14.8376C17.7942 14.622 17.7413 14.4098 17.6354 14.2009L9.94295 0.535692C9.83707 0.347021 9.69811 0.212255 9.52606 0.131396C9.36063 0.0437987 9.18528 0 9 0C8.81472 0 8.63605 0.0437987 8.46401 0.131396C8.29196 0.212255 8.153 0.347021 8.04713 0.535692L0.354666 14.1908C0.301729 14.2986 0.262026 14.4098 0.235557 14.5243C0.215706 14.6389 0.20578 14.7433 0.20578 14.8376C0.20578 15.1476 0.305037 15.4171 0.503553 15.6462C0.702068 15.8821 0.973372 16 1.31746 16ZM9 10.7644C8.49048 10.7644 8.2291 10.4982 8.21586 9.96589L8.08683 4.61908C8.08021 4.36302 8.15962 4.15077 8.32505 3.98231C8.4971 3.81386 8.71877 3.72963 8.99007 3.72963C9.25476 3.72963 9.47313 3.81722 9.64517 3.99242C9.82384 4.16088 9.90986 4.37313 9.90324 4.62919L9.75436 9.96589C9.74774 10.4982 9.49629 10.7644 9 10.7644ZM9 14.0493C8.70884 14.0493 8.45739 13.9482 8.24564 13.7461C8.03389 13.5372 7.92802 13.2879 7.92802 12.9981C7.92802 12.7084 8.03389 12.4624 8.24564 12.2603C8.45739 12.0514 8.70884 11.9469 9 11.9469C9.29115 11.9469 9.54261 12.048 9.75436 12.2502C9.96611 12.4523 10.072 12.7016 10.072 12.9981C10.072 13.2946 9.9628 13.5439 9.74443 13.7461C9.53268 13.9482 9.28454 14.0493 9 14.0493Z"
            fill="#FF9500" />
        </svg>
      </div>
      <span>Remove {{ state.selectedIndicator.name }} from you KPIs</span>
    </div>
    <div class="remove-content">
      <p>To remove the {{ state.selectedIndicator.name }}, please delete it from the following reports first.</p>
      <p>
        It’s currently used in
        <b>{{ state.listReportUsedIndicator?.length || 0 }} Reports</b>
        :
      </p>
      <div *ngIf="state.listReportUsedIndicator && state.listReportUsedIndicator.length > 0" class="report-list">
        <ng-container *ngFor="let report of state.listReportUsedIndicator">
          <div class="report-item">
            <div class="report-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M8 18H16V16H8V18ZM8 14H16V12H8V14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9V4H6V20H18V9H13Z"
                  fill="#5F6368" />
              </svg>
            </div>
            <a target="_blank" href="/report/{{ report.id }}">{{ report.name }}</a>
          </div>
        </ng-container>
      </div>
      <div class="message">
        If you wish to add the indicator in the future, you can find it in the Indicator Library.
      </div>
    </div>
    <div class="btns">
      <esg-button [ngClass]="'primary'" (onClick)="state.handleCloseNotPossibleToRemoveDialog()">Ok</esg-button>
    </div>
  </div>
</esg-dialog>
