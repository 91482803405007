<div class="title">
  {{ section.name }}
</div>
<div *ngIf="section.contents && section.contents.length" class="content">
  <div class="component" *ngFor="let component of section.contents">
    <esg-text-view-editor *ngIf="component?.textHtml" [content]="component?.textHtml"></esg-text-view-editor>
    <div class="frameworkReference" *ngIf="component?.indicatorType && component.indicatorType === indicatorTypeEnum.Narrative">
      <span class="reference-label">Disclosure Reference:&nbsp;</span>
      <span class="reference-list">{{ getKeyFigures(component) }}</span>
    </div>
    <esg-table-data
      *ngIf="component?.tableConfig"
      [editMode]="false"
      [config]="component.tableConfig"
      [rows]="component.rows"></esg-table-data>
    <img *ngIf="component?.blobUrl" [src]="component?.blobUrl" [alt]="component?.altText" />
    <ng-container *ngIf="component?.indicatorType && component.keyFigures">
      <ng-container *ngIf="component.indicatorType === indicatorTypeEnum.IndicatorTableValue">
        <esg-indicator-table-value-preview
          *ngFor="let table of component.keyFigures"
          [tableValue]="table"
          [indicatorPeriodLabels]="indicatorPeriodLabels"
          [visibleColumns]="component.visibleColumns || []"></esg-indicator-table-value-preview>
      </ng-container>
      <esg-indicator-set-preview
        *ngIf="component.indicatorType === indicatorTypeEnum.IndicatorSet"
        [keyFigures]="component.keyFigures"
        [indicatorPeriodLabels]="indicatorPeriodLabels"
        [visibleColumns]="component.visibleColumns || []"
        [isCustomPeriod]="isCustomPeriod"></esg-indicator-set-preview>
    </ng-container>
  </div>
</div>
