import { Injectable } from '@angular/core';
import { ITabItem } from 'src/app/shared/ui/tabs/tabs.component';
import { IndicatorApiService } from 'src/app/api-client/report-api/indicator-api-service';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import {
  IndicatorDefinitionProperties,
  IndicatorDetailsUi,
} from '../indicator-details/indicator-details-state.service';
import { FormControl } from '@angular/forms';

@Injectable()
export class NarrativeDetailsStateService {
  indicator!: IndicatorDetailsUi;
  indicatorDefinitonProperties?: IndicatorDefinitionProperties;
  narrativeContent!: FormControl<string>;

  loading: boolean = false;
  tabs: ITabItem[] = [{ value: 'Properties', label: 'Properties' }];
  activeTab = this.tabs[0].value;

  constructor(
    private indicatorApiService: IndicatorApiService,
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private notificationService: NotificationService
  ) {}

  async init(indicatorId: string) {
    try {
      this.loading = true;
      await this.getIndicatorDetails(indicatorId);
      await Promise.all([this.getIndicatorDefinitionData()]);
    } catch (error) {
      this.notificationService.showError('Unable to load indicator details');
    } finally {
      this.loading = false;
    }
  }

  async getIndicatorDetails(indicatorId: string) {
    const response = await this.indicatorApiService.getIndicatorById(indicatorId);

    this.indicator = {
      id: response.result.id,
      name: response.result.name,
      indicatorDefinitionId: response.result.indicatorDefinitionId,
    };
  }

  async getIndicatorDefinitionData() {
    if (this.indicator) {
      const definitionResponse = await this.indicatorDefinitionsApiService.getById(
        this.indicator.indicatorDefinitionId
      );

      this.indicatorDefinitonProperties = {
        valueType: definitionResponse.result.valueType,
        recordDuration: definitionResponse.result.recordDuration,
        description: definitionResponse.result.description,
        standards: definitionResponse.result.frameworkStandards,
        unit: definitionResponse.result.numericValueDefinition?.unit || '',
        unitName: definitionResponse.result.numericValueDefinition?.unit || '',
        numericCalculationType: definitionResponse.result.numericValueDefinition?.calculationType,
        category: definitionResponse.result.category,
        sectors: definitionResponse.result.sectors,
        rawDataSources: definitionResponse.result.indicatorRawDataSourceIds,
        indicatorDefinitionDataSources: definitionResponse.result.indicatorDefinitionAsDataSourceIds,
      };
    }
  }

  setActiveTab(tabValue: string) {
    this.activeTab = tabValue;
  }

  ngOnDestroy() {}
}
