<div class="topic-toolbar">
  <div class="topic-toolbar-content">
    <esg-tooltip
      *ngIf="shouldShowTooltip"
      text="{{ reportState.selectedTopic?.name }}"
      position="bottom"
      variant="dark"
      [underline]="false">
      <span class="topic-name tooltip" [class.fade]="state.reordering">
        {{ reportState.selectedTopic?.name || '' }}
      </span>
    </esg-tooltip>
    <span *ngIf="!shouldShowTooltip" class="topic-name tooltip" [class.fade]="state.reordering">
      {{ reportState.selectedTopic?.name || '' }}
    </span>
    <button
      class="topic-toolbar-button add-section"
      [class.fade]="state.reordering"
      (click)="state.handleOnAddHeader()">
      <esg-section-icon></esg-section-icon>
      <div class="label">Add section</div>
      <div *ngIf="state.topicTopicItems.length === 0 && !state.isLoading" class="show-empty-text">
        <div class="text">Click Add Section to start adding content</div>
        <esg-arrow-right-top></esg-arrow-right-top>
      </div>
    </button>
    <button *ngIf="!state.reordering" class="topic-toolbar-button" (click)="state.handleOnReordering()">
      <esg-drag-icon></esg-drag-icon>
      <div class="label">Organize</div>
    </button>
    <esg-button *ngIf="state.reordering" (click)="state.handleOnReorderCancel()">Done</esg-button>
  </div>
</div>
<div
  class="feed"
  [class.dialog-visible]="state.isTextDisclosureVisible"
  cdkDropList
  (cdkDropListDropped)="state.handleOnMoveTopicItem($event)"
  [class.reordering]="state.reordering">
  @for (item of state.topicTopicItems; track item.id; let last = $last){ @defer (on viewport) {
  <esg-topic-item-content
    [id]="item.id"
    cdkDrag
    [cdkDragDisabled]="!state.reordering"
    class="topic-item"
    [topicItem]="item"
    [reportId]="state.reportId"
    [indicatorOptions]="state.indicatorOptions"
    [indicatorSingleValueOptions]="state.indicatorSingleValueOptions"
    [narrativeIndicatorOptions]="state.narrativeIndicatorOptions"
    [indicatorPeriodLabels]="state.indicatorPeriodLabels"
    [responsiblePersonOptions]="state.responsiblePersonOptions"
    [reorderMode]="state.reordering"
    [showEmptyText]="state.topicTopicItems.length === 1"
    [scrollIntoViewOnInit]="state.fragmentSectionId === item.id"
    [isLastItem]="last"
    [isTextDisclosureVisible]="state.isTextDisclosureVisible"
    (onDeleteHeader)="state.handleOnInitateDeleteTopicItem(item.id)"
    (onResponsiblePersonChange)="state.updateResponsiblePerson(item.id, $event)"
    (onUpdateVisibility)="state.updateVisibility(item.id, $event)"
    (onSetTextDisclosure)="state.openTextDisclosureModal($event)"></esg-topic-item-content>
  } @placeholder {
  <esg-topic-item-placeholder [scrollIntoViewOnInit]="state.fragmentSectionId === item.id"></esg-topic-item-placeholder>
  } }
</div>

<div class="text-disclosure-dialog" *ngIf="state.isTextDisclosureVisible">
  <div class="content">
    <div class="toolbar">
      <button class="close" (click)="state.closeTextDisclosureModal()">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
          <rect
            x="2.11084"
            y="1.40381"
            width="12"
            height="1"
            rx="0.5"
            transform="rotate(45 2.11084 1.40381)"
            fill="#0071E3" />
          <rect
            x="1.40381"
            y="9.88916"
            width="12"
            height="1"
            rx="0.5"
            transform="rotate(-45 1.40381 9.88916)"
            fill="#0071E3" />
        </svg>
      </button>
    </div>
    <div class="container">
      <div class="modal-content">
        <div class="header">
          <esg-info-icon></esg-info-icon>
          <span>Description</span>
        </div>
        <div class="description">
          <esg-text-view-editor
            *ngIf="state.TextDisclosure"
            class="text"
            [content]="state.TextDisclosure"
            typography="standard"></esg-text-view-editor>
        </div>
      </div>
    </div>
  </div>
</div>

<esg-confirm-dialog
  *ngIf="state.removeTopicItemId"
  [message]="state.removeTopicItemMessage"
  (onCancel)="state.handleOnDeleteTopicItemCancel()"
  (onSubmit)="state.handleOnDeleteTopicItem()"></esg-confirm-dialog>
