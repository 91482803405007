<esg-topic-item-placeholder *ngIf="state.isLoadingComponents"></esg-topic-item-placeholder>

<ng-container *ngIf="!state.isLoadingComponents">
  <esg-topic-item-header
    [topicItemId]="topicItem.id"
    [headerText]="topicItem.name"
    [responsiblePerson]="topicItem.responsiblePerson"
    [visible]="topicItem.visible"
    [collapsed]="topicItem.collapsed"
    [reorderMode]="reorderMode"
    [responsiblePersonOptions]="responsiblePersonOptions"
    [showEmptyText]="showEmptyText && !state.dynamicContent.length"
    (onAddContent)="handleOnAddContent($event)"
    (onEdit)="handleOnEditHeader($event)"
    (onDelete)="handleOnDeleteHeader()"
    (onResponsiblePersonChange)="handleOnResponsiblePersonChange($event)"
    (onUpdateVisibility)="handleOnUpdateVisibility($event)"
    (onToggleCollapse)="handleOnToggleCollapse()"></esg-topic-item-header>
  <esg-content-list
    *ngIf="!topicItem.collapsed || reorderMode"
    [class.bottom-padding]="isLastItem"
    [readMode]="false"
    [reorderMode]="reorderMode"
    [hasVisibilityOption]="topicItem.visible"
    [isAllContentNotVisible]="!topicItem.visible"
    [isTextDisclosureVisible]="isTextDisclosureVisible"
    [toolbarTop]="29"
    cdkDropList
    (cdkDropListDropped)="state.moveComponent($event)"
    [onChange]="state.onDataChangeComponent"
    [contentItems]="state.dynamicContent"
    (onDelete)="state.handleOnInitateDeleteComponent($event)"
    (onSetTextDisclosure)="handleOnSetTextDisclosure($event)"></esg-content-list>
  <esg-confirm-dialog
    *ngIf="state.removeContentId"
    [message]="state.removeContentMessage"
    (onCancel)="state.handleOnDeleteComponentCancel()"
    (onSubmit)="state.deleteComponent()"></esg-confirm-dialog>
</ng-container>
