import htmlToPdfmake from 'html-to-pdfmake';
import { Injectable, resolveForwardRef } from '@angular/core';
import { ReportAppApiService } from 'src/app/api-client/report-api/app-api-service';
import { TDocumentDefinitions, Content, ContentImage, TFontDictionary } from 'pdfmake/interfaces';
import { generateReport, generateVfs, throwErrorIfPdfDefinitionNotValid } from 'src/app/pdfmake-common/generate-report';
import { IReportPdf, IComponent, IReportPdfConfiguration, IKeyfigures } from './types';
import { keyFiguresTable, generateKeyFiguresTable, getKeyFigureFromMainLevels } from './key-figures-table-pdf';
import { ITextAreaContentData } from '../../../content/content-item/text-area/text-area.component';
import { IPictureContentData } from '../../../content/content-item/picture/picture.component';
import { getBase64ImageFromURL } from '../../../shared/utils/file';
import { AppConfigurationService } from 'src/app/core/app-configuration.service';
import {
  frontPage,
  footer,
  defaultHTMLStyles,
  pdfStyles,
  pageBreak,
  generateChapterPage,
  generateTopicTitle,
} from './report-pdf-constants';
import { logo } from './logo';
import { fontsFactory, FontTypes } from '../../../pdfmake-common/fonts';
import { IImportFromExcelContentData } from 'src/app/content/content-item/import-from-excel/import-from-excel.component';
import { importFromExcelTable } from './components/import-from-excel';
import _ from 'lodash';
import {
  IIndicatorContentData,
  IndicatorContentType,
} from 'src/app/content/content-item/indicator-content/indicator-content.component';
import { ComponentType, TimePeriod } from 'src/api-client/report-api.generated';
import { generateIndicatorSet } from './components/indicator-set-pdf';
import { generateTableValue } from './components/indicator-table-value-pdf';
import { ITextDisclosureData } from 'src/app/content/content-item/text-disclosure/text-disclosure.component';

@Injectable({
  providedIn: 'root',
})
export class ReportPdfService {
  constructor(private appConfig: AppConfigurationService, private appApiService: ReportAppApiService) {}

  getPictureUrl(blobName: string): string {
    return this.appApiService.getBlobUrl(blobName);
  }

  getCoverPhotoUrl(blobName: string) {
    return `${this.appConfig.ReportApiBaseUrl}/report/cover-photo/${blobName}`;
  }

  replaceListItems(html: string, listType: 'ul' | 'ol', itemPrefix: (index: number) => string): string {
    let counter = 1;
    return html.replace(new RegExp(`<${listType}>([\\s\\S]*?)<\\/${listType}>`, 'g'), (_, listItems: string) => {
      return listItems.replace(/<li([^>]*)>(.*?)<\/li>/g, (_, attributes: string, content: string) => {
        return `<p ${attributes.trim()} style="display: flex; align-items: center;">
                  <span style="margin-right: 5px;">${itemPrefix(counter++)}</span>
                  <span>${content}</span>
                </p>`;
      });
    });
  }

  replaceHorizontalRule(html: string): string {
    return html.replace(
      /<hr>/g,
      '<hr data-pdfmake="{&quot;color&quot;:&quot;#CCCCCC&quot;, &quot;margin&quot;:[0,6,0,6], &quot;thickness&quot;:1, &quot;left&quot;:122}">'
    );
  }

  formatListItems(html: string): string {
    html = this.replaceListItems(html, 'ul', () => '•');
    html = this.replaceListItems(html, 'ol', index => `${index}.`);
    return html;
  }

  HTMlToContent(html: string): Content {
    html = this.formatListItems(html);
    html = this.replaceHorizontalRule(html);
    return htmlToPdfmake(html, defaultHTMLStyles);
  }

  contentToPdf = {
    [ComponentType.Text]: async (content: ITextAreaContentData) => {
      let textHtml = /\<[^\>]+\>/.test(content.textHtml) ? content.textHtml : '<p>' + content.textHtml + '</p>';
      return textHtml ? this.HTMlToContent(textHtml) : '';
    },
    [ComponentType.Picture]: async (content: IPictureContentData): Promise<ContentImage | Content> => {
      const image = await getBase64ImageFromURL(this.getPictureUrl(content.blobName));
      return image
        ? {
            image: image,
            width: 368,
            margin: [122, 0, 14, 0],
          }
        : { text: '' };
    },
    [ComponentType.ImportFromExcel]: async (content: IImportFromExcelContentData): Promise<Content> =>
      importFromExcelTable(content),
    [ComponentType.Indicator]: async (
      content: IIndicatorContentData,
      keyFigures: IKeyfigures[],
      timePeriod?: TimePeriod
    ): Promise<Content> => {
      const usedKeyFigures = content?.indicatorIds
        .map(id => keyFigures.find(value => value.indicatorId === id)!)
        .filter(Boolean);
      if (content.indicatorType === IndicatorContentType.IndicatorSet) {
        return generateIndicatorSet(content, usedKeyFigures, timePeriod);
      } else if (content.indicatorType === IndicatorContentType.IndicatorTableValue) {
        return generateTableValue(content, usedKeyFigures[0], timePeriod);
      }
      return { text: '' };
    },
    [ComponentType.Narrative]: async (content: ITextDisclosureData, keyFigures: IKeyfigures[]): Promise<Content> => {
      let textHtml = /\<[^\>]+\>/.test(content.textHtml) ? content.textHtml : '<p>' + content.textHtml + '</p>';

      const usedKeyFigures = content?.indicatorIds
        .map(id => keyFigures.find(value => value.indicatorId === id)!)
        .filter(Boolean);

      const references = Array.from(
        new Set(usedKeyFigures.flatMap(value => value.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ', ').split(', ')))
      ).join(', ');

      if (references) {
        textHtml += '<div class="textDisclosureReference">' + references + '</div>';
      }
      return textHtml ? this.HTMlToContent(textHtml) : '';
    },
  };

  generateComponentsContent(
    components: IComponent[],
    keyFigures: IKeyfigures[],
    timePeriod?: TimePeriod
  ): Promise<Content>[] {
    let content: Promise<Content>[] = [];
    components
      .filter(c => c.isVisibleInReport)
      .map(async c => {
        if (c.type && c.content) {
          if (c.type === ComponentType.Indicator) {
            content.push(this.contentToPdf[c.type](JSON.parse(c.content), keyFigures, timePeriod));
            content.push(Promise.resolve({ text: ' ', fontSize: 0, margin: [122, 0, 0, 28] }));
          } else if (c.type === ComponentType.Narrative) {
            content.push(this.contentToPdf[c.type](JSON.parse(c.content), keyFigures));
            content.push(Promise.resolve({ text: ' ', fontSize: 0, margin: [122, 0, 0, 28] }));
          } else {
            content.push(this.contentToPdf[c.type](JSON.parse(c.content)));
          }
          content.push(Promise.resolve({ text: ' ', fontSize: 0, margin: [122, 0, 0, 28] }));
        }
      });
    return content;
  }

  async generateReportContent(
    reportData: IReportPdf,
    fonts: TFontDictionary,
    vfs: { [file: string]: string },
    showMainContent: boolean = true
  ): Promise<Content[]> {
    let content: Content[] = [];
    let keyFiguresTables: keyFiguresTable[] = getKeyFigureFromMainLevels(reportData.mainLevels || []);

    let mainContent: Promise<Content>[] = [];
    const mainLevels = reportData.mainLevels || [];
    if (showMainContent) {
      mainLevels.map(async mainTopic => {
        const topics = mainTopic.topics || [];
        topics.length &&
          generateChapterPage(
            mainTopic.name || '',
            reportData.organizationDetails.organizationName || '',
            reportData.timePeriod,
            reportData.organizationDetails.brandColor
          ).map(c => mainContent.push(Promise.resolve(c)));

        topics.map(async (topic, topicIndex) => {
          const topicItems = topic.topicItems || [];
          topicItems.length &&
            mainContent.push(
              Promise.resolve(generateTopicTitle(mainTopic.name, topic.name, reportData.organizationDetails.brandColor))
            );
          topicItems.map(async (topicItem, topicItemIndex) => {
            const topicItemErrorId = `${mainTopic.name || ''}/${topic.name || ''}/${topicItem.name || ''}`;
            mainContent.push(Promise.resolve({ text: topicItem.name || '', style: 'topicItemTitle' }));
            this.generateComponentsContent(
              topicItem.components || [],
              topicItem.dataValues || [],
              reportData.timePeriod
            ).map(async c => {
              mainContent.push(this.validateContent(topicItemErrorId, c, fonts, vfs));
            });
            if (!(topicIndex === topics.length - 1 && topicItemIndex === topicItems.length - 1)) {
              mainContent.push(Promise.resolve({ text: ' ', fontSize: 0, margin: [122, 0, 0, 14] }));
            }
          });
        });
      });
    }

    const keyFiguresContent: Content[] = keyFiguresTables.length
      ? generateKeyFiguresTable(
          keyFiguresTables,
          reportData.timePeriod,
          reportData.organizationDetails.organizationName,
          reportData.organizationDetails.tableHeadColor
        )
      : [];

    return content.concat(keyFiguresContent, await Promise.all(mainContent));
  }

  async generatePdf(reportData: IReportPdf, appBaseUrl: string, configuration: IReportPdfConfiguration) {
    let content: Content[] = [];

    let frontPageContent: Content[] = frontPage(
      configuration,
      reportData.coverPhotoSquare?.blobName
        ? await getBase64ImageFromURL(this.getCoverPhotoUrl(reportData.coverPhotoSquare.blobName))
        : '',
      reportData.name || '',
      reportData.organizationDetails.organizationName || '',
      reportData.organizationDetails.brandColor,
      reportData.organizationDetails.logo
        ? await getBase64ImageFromURL(this.getPictureUrl(reportData.organizationDetails.logo))
        : ''
    );

    const pageMarginTop = 85;
    const fonts = fontsFactory(appBaseUrl)(configuration.writingSystem);
    const vfs = await generateVfs(fonts);

    const finishedContent = await this.generateReportContent(reportData, fonts, vfs, configuration.showMainContent);

    let dd: TDocumentDefinitions = {
      pageMargins: [34, pageMarginTop, 57, 71],
      pageSize: 'A4', // pixels: [595.28, 841.89], in mm: [210, 297]. 1mm = 2,835px
      content: content.concat([frontPageContent, finishedContent]),
      footer: currentPage =>
        footer(
          currentPage,
          reportData.name || '',
          reportData.organizationDetails.organizationName || '',
          configuration
        ),
      styles: pdfStyles,
      defaultStyle: {
        font: FontTypes.Regular,
      },
      images: {
        logo: logo,
      },
      pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage) =>
        pageBreak(currentNode, followingNodesOnPage, nodesOnNextPage, pageMarginTop),
    };
    let pdfUrl = await generateReport(dd, fonts, vfs);
    return pdfUrl;
  }

  async validateContent(
    id: string,
    content: Promise<Content>,
    fonts: TFontDictionary,
    vfs: { [file: string]: string }
  ) {
    let dd: TDocumentDefinitions = {
      content: _.cloneDeep(await content),
      defaultStyle: {
        font: FontTypes.Regular,
      },
      styles: pdfStyles,
    };

    throwErrorIfPdfDefinitionNotValid(id, dd, fonts, vfs);

    return content;
  }
}
