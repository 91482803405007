import { Content, StyleDictionary, Node } from 'pdfmake/interfaces';
import { TimePeriod } from 'src/api-client/report-api.generated';
import { getTimeLabelFromPeriod } from 'src/app/static-data/time-labels';
import { fixLineHeight, FontTypes } from '../../../pdfmake-common/fonts';
import { IReportPdfConfiguration } from './types';
import { pickTextColorBasedOnBgColor } from 'src/app/shared/utils/colors';

export const generateTopicTitle = function (mainTopic?: string, topic?: string, brandColor?: string): Content {
  return {
    unbreakable: true,
    style: 'topicTitleStack',
    stack: [
      {
        margin: [0, 0, 14, 9],
        table: {
          widths: ['100%'],
          body: [
            [
              {
                text: mainTopic?.toUpperCase() || '',
                style: 'mainTopicTitleLeft',
                border: [false, false, false, false],
                margin: [0, 0, 0, 7],
              },
            ],
            [
              {
                text: topic?.toUpperCase() || '',
                style: 'topicTitle',
                border: [false, true, false, false],
                margin: [0, 9, 0, 0],
              },
            ],
          ],
        },
        layout: {
          hLineColor: brandColor ? brandColor : '#4AD1DA',
          vLineColor: brandColor ? brandColor : '#4AD1DA',
          hLineWidth: function () {
            return 0.5;
          },
          paddingRight: function () {
            return 405;
          },
          paddingLeft: function () {
            return 0;
          },
          paddingTop: function () {
            return 0;
          },
          paddingBottom: function () {
            return 0;
          },
        },
      },
    ],
  };
};

export const generateChapterPage = function (
  mainTopicTitle: string,
  organizationName: string,
  timePeriod?: TimePeriod,
  brandColor?: string
): Content[] {
  return [
    {
      absolutePosition: { x: 0, y: 0 },
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 595.28,
          h: 841.89,
          color: brandColor ? brandColor : '#4AD1DA',
          fillOpacity: 1,
        },
      ],
      pageBreak: 'before',
    },
    {
      table: {
        widths: ['auto', 'auto', 'auto'],
        body: [
          [
            {
              text: organizationName.toUpperCase(),
              margin: [0, 0, 4, 0],
              style: 'companyNameCover',
              color: brandColor ? pickTextColorBasedOnBgColor(brandColor) : 'white',
            },
            {
              canvas: [
                {
                  type: 'line',
                  x1: 0,
                  y1: 3,
                  x2: 0,
                  y2: 11,
                  lineWidth: 0.5,
                  lineColor: brandColor ? pickTextColorBasedOnBgColor(brandColor) : 'white',
                },
              ],
            },
            {
              text: timePeriod ? getTimeLabelFromPeriod(timePeriod, 0).toUpperCase().replace('\n', '') : '',
              margin: [4, 0, 0, 0],
              style: 'companyNameCover',
              color: brandColor ? pickTextColorBasedOnBgColor(brandColor) : 'white',
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
      },
      margin: [51, 113, 28, 42],
    },
    {
      text: mainTopicTitle || '',
      style: 'mainTopicTitleCover',
      color: brandColor ? pickTextColorBasedOnBgColor(brandColor) : 'white',
      margin: [51, 0, 28, 0],
      pageBreak: 'after',
    },
  ];
};

export const frontPage = function (
  configuration: IReportPdfConfiguration,
  coverPhoto: string,
  reportTitle: string,
  organization: string,
  brandColor?: string,
  logo?: string
): Content[] {
  const content: Content[] = coverPhoto
    ? [
        {
          image: coverPhoto,
          fit: [595.35, 595.35],
          alignment: 'center',
          margin: [0, -85, -12, 0],
        },
      ]
    : [
        {
          absolutePosition: { x: 0, y: 0 },
          canvas: [
            {
              type: 'rect',
              x: 0,
              y: 0,
              w: 595.28,
              h: 595.35,
              color: '#444444',
              fillOpacity: 1,
            },
          ],
        },
        { text: ' ', fontSize: 0, margin: [-34, -85, 0, 595.35] },
      ];
  content.push({
    canvas: [
      {
        type: 'line',
        x1: 11.5,
        y1: -10,
        x2: 380.5,
        y2: -10,
        lineWidth: 23,
        lineColor: 'white',
      },
    ],
    alignment: 'center',
  });
  content.push({
    text: organization.toUpperCase(),
    style: 'companyName',
    alignment: 'center',
    margin: [11.5, 0, 0, 20],
  });
  content.push({
    canvas: [
      {
        type: 'line',
        x1: 11.5,
        y1: 0,
        x2: 67.5,
        y2: 0,
        lineWidth: 2,
        lineColor: brandColor ? brandColor : '#4AD1DA',
      },
    ],
    alignment: 'center',
  });
  content.push({
    text: reportTitle,
    style: 'reportTitle',
    alignment: 'center',
    margin: [11.5, 20, 0, 0],
  });

  configuration.isExecutiveSummary &&
    content.push({
      text: 'EXECUTIVE SUMMARY',
      fontSize: 10,
      characterSpacing: 1.5,
      color: brandColor,
      alignment: 'center',
      margin: [11.5, 13, 0, 0],
    });

  content.push({
    text: '',
    margin: [0, 0, 0, configuration.isExecutiveSummary ? 41 : 51],
  });

  logo &&
    content.push({
      alignment: 'center',
      margin: [11.5, 0, 0, 0],
      columns: [
        {
          image: logo,
          fit: [369, 34],
        },
      ],
    });

  return content;
};

export const footer = function (
  currentPage: number,
  reportTitle: string,
  organization: string,
  configuration: IReportPdfConfiguration
): Content {
  if (currentPage != 1) {
    return {
      table: {
        widths: ['*', 'auto', '*'],
        body: [
          [
            {
              text: organization + (configuration.isExecutiveSummary ? ' - Executive Summary' : ''),
              border: [false, false, false, false],
              alignment: 'right',
              marginRight: 8,
              style: 'footerText',
            },
            {
              text: currentPage,
              bold: true,
              border: [false, false, false, false],
              alignment: 'center',
              style: 'footerText',
            },
            {
              text: reportTitle,
              border: [false, false, false, false],
              alignment: 'left',
              marginLeft: 8,
              style: 'footerText',
            },
          ],
        ],
      },
      margin: [0, 22, 0, 0],
    };
  } else {
    return { text: '' };
  }
};

export const pageBreak = function (
  currentNode: Node,
  followingNodesOnPage: Node[],
  nodesOnNextPage: Node[],
  pageMarginTop: number
) {
  const style = Array.isArray(currentNode.style) && currentNode.style.length ? currentNode.style[0] : currentNode.style;
  // Footer nodes are counted, therefore followingNodesOnPage.length === {number of footerNodes} means last element
  switch (style) {
    case 'topicTitleStack':
      // 4 + 3 nodes in topicTitleStack
      if (
        followingNodesOnPage.length === 7 ||
        (followingNodesOnPage.length === 8 && followingNodesOnPage[3].style === 'topicItemTitle') ||
        (followingNodesOnPage.length < 22 && followingNodesOnPage[3].style === 'indicatorStack') // 4 + 3 + 14
      )
        return true;
      else return false;
    case 'indicatorStack':
      if (followingNodesOnPage.length === 5) {
        return true;
      } else return false;
    case 'html-p':
      if (currentNode.pageNumbers.length > 1) {
        const pageInnerHeight = currentNode.startPosition.pageInnerHeight;
        const top = currentNode.startPosition.top ? currentNode.startPosition.top : 0;

        // lines < 2 bottom of page
        if (pageInnerHeight + pageMarginTop - top < 30) return true;
        // lines < 2 top of new page
        else if (nodesOnNextPage && nodesOnNextPage.length && nodesOnNextPage[0].startPosition.top - pageMarginTop < 15)
          return true;
        else return false;
      } else return false;
    default:
      return false;
  }
};

export const defaultHTMLStyles = {
  defaultStyles: {
    p: {
      margin: 0,
      padding: 0,
      marginLeft: 122,
      marginRight: 14,
      fontSize: 10,
      lineHeight: fixLineHeight(1.4),
    },
    h2: {
      margin: 0,
      padding: 0,
      marginLeft: 122,
      marginRight: 14,
      fontSize: 11,
      bold: false,
      font: FontTypes.SemiBold,
      lineHeight: fixLineHeight(1.27),
    },
    li: { margin: 0, padding: 0, marginLeft: 130, fontSize: 10, lineHeight: fixLineHeight(1.4) },
    strong: { color: '#555555', bold: true },
  },
};

export const pdfStyles: StyleDictionary = {
  reportTitle: {
    fontSize: 20,
    lineHeight: fixLineHeight(1),
  },
  companyName: {
    font: FontTypes.Light,
    fontSize: 10,
    lineHeight: fixLineHeight(1),
    characterSpacing: 0.3,
  },
  mainTopicTitleCover: {
    font: FontTypes.Light,
    fontSize: 48,
    lineHeight: fixLineHeight(1),
    color: 'white',
  },
  companyNameCover: {
    fontSize: 9,
    lineHeight: fixLineHeight(1),
    characterSpacing: 0.3,
  },
  mainTopicTitleLeft: {
    fontSize: 6,
    lineHeight: fixLineHeight(1),
    characterSpacing: 0.3,
  },
  keyFigureTitle: {
    fontSize: 24,
    bold: true,
    lineHeight: fixLineHeight(1.16),
    margin: [0, 0, 0, 12],
  },
  topicTitle: {
    font: FontTypes.SemiBold,
    fontSize: 9,
    lineHeight: fixLineHeight(1.22),
    characterSpacing: 0.3,
  },
  topicItemTitle: {
    fontSize: 13,
    lineHeight: fixLineHeight(1.077),
    margin: [122, 0, 14, 14],
  },
  keyFiguresTableCell: {
    fontSize: 7,
    lineHeight: fixLineHeight(1.3),
    margin: [0, 4, 0, 0],
  },
  indicatorHeading: {
    fontSize: 6,
    lineHeight: fixLineHeight(1),
    characterSpacing: 0.3,
    bold: true,
    color: '#666666',
  },
  indicatorValue: {
    fontSize: 8,
    lineHeight: fixLineHeight(1.25),
  },
  indicatorTableHeading: {
    fontSize: 10,
    lineHeight: fixLineHeight(1.4),
    margin: [122, 0, 14, 8],
    bold: true,
    color: '#555555',
  },
  indicatorReference: {
    fontSize: 7,
    lineHeight: fixLineHeight(1.14),
    italics: true,
  },
  excelHeader: {
    fontSize: 7,
    font: FontTypes.SemiBold,
    lineHeight: fixLineHeight(1.3),
  },
  excelBody: {
    fontSize: 7,
    lineHeight: fixLineHeight(1.3),
  },
  footerText: {
    fontSize: 7,
    lineHeight: fixLineHeight(1.3),
  },
  'ql-align-center': {
    alignment: 'center',
  },
  'ql-align-right': {
    alignment: 'right',
  },
  textDisclosureReference: {
    fontSize: 7,
    lineHeight: fixLineHeight(1.14),
    italics: true,
    margin: [122, 0, 14, 0]
  },
};
