import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IGroupedOptions, IOption } from 'src/app/static-data/options';
import { TextDisclosureValuesUi } from '../text-disclosure.component';

interface Style {
  [key: string]: string;
}

@Component({
  selector: '[esg-text-disclosure-footer]',
  templateUrl: './text-disclosure-footer.component.html',
  styleUrls: ['./text-disclosure-footer.component.scss'],
})
export class TextDisclosureFooterComponent implements OnInit {
  @Input() isVisible: boolean = true;
  @Input() active: boolean = false;
  @Input() isTextDisclosureVisible: boolean = false;
  @Input() indicatorOptions: IGroupedOptions[] = [];
  @Input() indicatorValue?: TextDisclosureValuesUi = undefined;
  @Output() onUpdateVisibility = new EventEmitter<boolean>();
  @Output() onAddIndicator = new EventEmitter<IOption>();
  @Output() onToggleModal = new EventEmitter<any>();
  @Output() onSetTextDisclosure = new EventEmitter<string>();

  indicatorSelectCollapsed: boolean = true;
  stickyToolbar: boolean = false;
  dialogStyle: Style = { 'margin-top': '72px', 'padding-bottom': '72px' };

  constructor() {}

  get references() {
    return this.indicatorValue?.referenceStandards?.replace(/(\r\n|\n|\r)/gm, ' | ') || '';
  }
  get description() {
    return this.indicatorValue?.description;
  }

  ngOnInit(): void {}

  ngAfterViewChecked(): void {}

  handleOnIndicatorSelectClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.indicatorSelectCollapsed = !this.indicatorSelectCollapsed;
  }

  handleOnIndicatorSelectCollapse(collapse: boolean) {
    this.indicatorSelectCollapsed = collapse;
  }

  toggleModal(event: Event): void {
    event.preventDefault();
    this.onToggleModal.emit(event);
  }
}
