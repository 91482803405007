import { Injectable } from '@angular/core';
import {
  CalculationType,
  IndicatorCategory,
  IndicatorNumericValueDefinition,
  IndicatorTableValueDefinition,
  IndicatorValueType,
  RecordDuration,
  SectorType,
  UpdateIndicatorDefinitionCommandParams,
} from 'src/api-client/report-api.generated';
import { IndicatorDefinitionApiService } from 'src/app/api-client/report-api/indicator-definition-api-service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AccessibilityOptions,
  AccessibilityTypes,
  DurationOptions,
  IGroupedOptions,
  IOption,
} from 'src/app/static-data/options';
import { SectorApiService } from 'src/app/api-client/report-api/sector-api-service';
import { Subscription, debounceTime, pairwise, startWith } from 'rxjs';
import { UnitService } from 'src/app/shared/services/unit/unit.service';
import { IndicatorDefinitionUi } from '../admin-indicator-library.component';
import { defaultStaticTable } from '../indicator-definition-table/default-table-definitions';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { IndicatorDataSourceApiService } from 'src/app/api-client/report-api/indicator-data-source-api-service';
import { FormatUnitWithUnicodePipe } from 'src/app/shared/pipes/format-unit-with-unicode.pipe';
import { OrganizationApiService } from 'src/app/api-client/report-api/organization-api-service';
import { Constants } from 'src/app/static-data/constants';

interface IndicatorDefinitionForm {
  accessiblity: FormControl<IOption>;
  accesibilityCustomOrganiations: FormControl<IOption[]>;
  valueType: FormControl<IOption>;
  unit: FormControl<IOption | undefined>;
  unitLabel: FormControl<string>;
  tableDefinition: FormControl<IndicatorTableValueDefinition | undefined>;
  description: FormControl<string>;
  referenceStandars: FormControl<string>;
  category: FormControl<IOption>;
  sectors: FormControl<IOption[]>;
  rawDataSources: FormControl<IOption[]>;
  indicatorDefinitionDataSources: FormControl<IOption[]>;
  calculationType: FormControl<IOption | undefined>;
  recordDuration: FormControl<IOption>;
}

@Injectable()
export class IndicatorDefinitionDetailsStateService {
  private indicatorAccessibilitySubscription?: Subscription;
  private indicatoCustomrAccessibilitySubscription?: Subscription;
  private unitOptionsSubscription?: Subscription;
  private sectorsSubscription?: Subscription;
  private rawSourcesSubscription?: Subscription;
  private indicatorSourcesSubscription?: Subscription;
  readonly valueTypeEnum = IndicatorValueType;

  indicatorDefinition?: IndicatorDefinitionUi;
  indicatorForm?: IndicatorDefinitionForm;
  indicatorFormGroup?: FormGroup<IndicatorDefinitionForm>;

  accessibilityTypesEnum = AccessibilityTypes;
  accessibilityOptions: IOption[] = AccessibilityOptions;
  organizationOptions: IOption[] = [];

  unitOptions: IOption[] = [];
  sectorOptions: IOption[] = [];
  rawDataSourceOptions: IOption[] = [];
  indicatorDefinitionDataSourceOptions: IOption[] = [];
  categoryOptions: IOption[] = Object.values(IndicatorCategory).map(c => ({ value: c, label: c }));
  valueTypeOptions: IOption[] = [
    { value: IndicatorValueType.Numeric, label: 'Single Value' },
    { value: IndicatorValueType.Table, label: 'Table Value' },
    { value: IndicatorValueType.Narrative, label: 'Narrative Value' },
  ];
  recordDurationOptions = DurationOptions;
  calculationTypeOptions: IOption[] = [
    { value: CalculationType.Sum, label: 'Summarize' },
    { value: CalculationType.Average, label: 'Average (Mean)' },
    { value: CalculationType.LastValue, label: 'Last value' },
  ];

  loading: boolean = false;
  isTableDialogOpen = false;

  constructor(
    private indicatorDefinitionsApiService: IndicatorDefinitionApiService,
    private indicatorDataSourceClientApiService: IndicatorDataSourceApiService,
    private sectorApiService: SectorApiService,
    private unitService: UnitService,
    private notificationService: NotificationService,
    private formatUnitWithUnicodePipe: FormatUnitWithUnicodePipe,
    private organizationApiService: OrganizationApiService
  ) {
    this.unitOptionsSubscription = this.unitService.units$.subscribe(units => {
      this.unitOptions = Object.values(units).map(unit => ({
        value: unit.key,
        label: this.formatUnitWithUnicodePipe.transform(unit.name) || unit.name,
      }));
    });
  }

  async init(indicatorId: string) {
    try {
      this.loading = true;
      await Promise.all([
        this.getOrganizationOptions(),
        this.getCompatibleRawDataSources(indicatorId),
        this.getCompatibleIndicatorDefinitionDataSources(indicatorId),
        this.getSectors(),
      ]);
      await this.getIndicatorDetails(indicatorId);
    } catch (error) {
      this.notificationService.showError('Unable to load indicator details');
    } finally {
      this.loading = false;
    }
  }

  async getIndicatorDetails(indicatorId: string) {
    const response = await this.indicatorDefinitionsApiService.getById(indicatorId);
    this.indicatorDefinition = {
      id: response.result.id,
      name: response.result.name,
      category: response.result.category,
      valueType: response.result.valueType,
      description: response.result.description,
      tableValueDefinition: response.result.tableValueDefinition,
      numericValueDefinition: response.result.numericValueDefinition,
      frameworkStandards: response.result.frameworkStandards,
      sectors: response.result.sectors,
      rawDataSourceIds: response.result.indicatorRawDataSourceIds,
      indicatorDefinitionDataSourceIds: response.result.indicatorDefinitionAsDataSourceIds,
      organizationAccessIds: response.result.organizationAccessIds,
      isIndicatorFromSystem: response.result.isIndicatorFromSystem,
      recordDuration: response.result.recordDuration,
      isUsed: response.result.isUsed,
    };

    const filteredSectors = response.result.sectors.map(sectorValue => {
      const matchingSector = this.sectorOptions.find(sectorOption => sectorOption.value === sectorValue);
      return matchingSector || { value: sectorValue, label: sectorValue };
    });

    // const accessibility = (response.result.organizationAccessIds || [draftOption.value]).map(organizationAccessId => {
    //   const accessibilityOption = this.accessibilityOptions.find(option => option.value === organizationAccessId);
    //   return accessibilityOption || { value: organizationAccessId, label: organizationAccessId };
    // });

    const organizationAccessIds = response.result.organizationAccessIds;
    const accessibility = !organizationAccessIds
      ? AccessibilityOptions[0]
      : organizationAccessIds.length === 1 && organizationAccessIds[0] === Constants.GlobalKey
      ? AccessibilityOptions[1]
      : AccessibilityOptions[2];

    const accesibilityCustomOrganiations =
      organizationAccessIds && accessibility.value === AccessibilityTypes.Custom
        ? organizationAccessIds.map(organizationAccessId => {
            const organizationOption = this.organizationOptions.find(option => option.value === organizationAccessId);
            return organizationOption || { value: organizationAccessId, label: organizationAccessId };
          })
        : [];

    const rawDataSources = response.result.indicatorRawDataSourceIds.map(sourceId => {
      const matchingSource = this.rawDataSourceOptions.find(source => source.value === sourceId);
      return matchingSource || { value: sourceId, label: sourceId };
    });

    const indicatorDefinitionDataSources = response.result.indicatorDefinitionAsDataSourceIds.map(sourceId => {
      const matchingSource = this.indicatorDefinitionDataSourceOptions.find(source => source.value === sourceId);
      return matchingSource || { value: sourceId, label: sourceId };
    });

    this.indicatorForm = {
      accessiblity: new FormControl<IOption>(accessibility, {
        nonNullable: true,
      }),
      accesibilityCustomOrganiations: new FormControl<IOption[]>(accesibilityCustomOrganiations, { nonNullable: true }),
      valueType: new FormControl<IOption>(
        this.valueTypeOptions.find(opt => opt.value === response.result.valueType) || this.valueTypeOptions[0],
        { nonNullable: true }
      ),
      recordDuration: new FormControl<IOption>(
        this.recordDurationOptions.find(opt => opt.value === response.result.recordDuration) ||
          this.recordDurationOptions[0],
        {
          nonNullable: true,
        }
      ),
      unit: new FormControl<IOption | undefined>(
        {
          value:
            response.result.valueType === IndicatorValueType.Table
              ? undefined
              : response.result.numericValueDefinition?.unit
              ? this.unitOptions.find(opt => opt.value === response.result.numericValueDefinition?.unit) || {
                  value: response.result.numericValueDefinition?.unit,
                  label: response.result.numericValueDefinition?.unit,
                }
              : undefined,
          disabled: response.result.valueType === IndicatorValueType.Table,
        },
        {
          nonNullable: true,
        }
      ),
      unitLabel: new FormControl<string>(
        {
          value:
            response.result.valueType === IndicatorValueType.Table
              ? ''
              : response.result.numericValueDefinition?.unitLabel || '',
          disabled: response.result.valueType === IndicatorValueType.Table,
        },
        {
          nonNullable: true,
        }
      ),
      tableDefinition: new FormControl<IndicatorTableValueDefinition | undefined>(
        response.result.tableValueDefinition || undefined,
        {
          nonNullable: true,
        }
      ),
      description: new FormControl<string>(response.result.description, {
        nonNullable: true,
      }),
      referenceStandars: new FormControl<string>(response.result.frameworkStandards, {
        nonNullable: true,
      }),
      category: new FormControl<IOption>(
        this.categoryOptions.find(opt => opt.value === response.result.category) || this.categoryOptions[0],
        {
          nonNullable: true,
        }
      ),
      sectors: new FormControl<IOption[]>(filteredSectors, {
        nonNullable: true,
      }),
      rawDataSources: new FormControl<IOption[]>(
        { value: rawDataSources, disabled: this.rawDataSourceOptions.length < 1 },
        {
          nonNullable: true,
        }
      ),
      indicatorDefinitionDataSources: new FormControl<IOption[]>(
        { value: indicatorDefinitionDataSources, disabled: this.indicatorDefinitionDataSourceOptions.length < 1 },
        {
          nonNullable: true,
        }
      ),
      calculationType: new FormControl<IOption | undefined>(
        {
          value:
            response.result.valueType === IndicatorValueType.Table
              ? undefined
              : this.calculationTypeOptions.find(
                  opt => opt.value === response.result.numericValueDefinition?.calculationType
                ) || this.calculationTypeOptions[0],
          disabled: response.result.valueType === IndicatorValueType.Table,
        },
        {
          nonNullable: true,
        }
      ),
    };
    this.indicatorFormGroup = new FormGroup(this.indicatorForm);

    this.sectorsSubscription = this.indicatorForm.sectors.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.updateIndicator();
    });

    this.rawSourcesSubscription = this.indicatorForm.rawDataSources.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateIndicator();
      });

    this.indicatorSourcesSubscription = this.indicatorForm.indicatorDefinitionDataSources.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateIndicator();
      });

    this.indicatorAccessibilitySubscription = this.indicatorForm.accessiblity.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateIndicator();
      });

    this.indicatoCustomrAccessibilitySubscription = this.indicatorForm.accesibilityCustomOrganiations.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.updateIndicator();
      });
  }

  async getSectors() {
    const response = await this.sectorApiService.getAll();
    this.sectorOptions = response.result.map(s => ({ value: s.type, label: s.name }));
  }

  async getOrganizationOptions() {
    const response = await this.organizationApiService.getOrganizationList();
    this.organizationOptions = response.result
      .filter(o => o.organizationId !== Constants.GlobalKey)
      .map(org => ({ value: org.organizationId, label: org.name }));
  }

  async getCompatibleRawDataSources(indicatorDefinitionId: string) {
    const response = await this.indicatorDataSourceClientApiService.getRawDataSourcesCompatibleWithIndicatorDefinition(
      indicatorDefinitionId
    );
    this.rawDataSourceOptions = response.result.map(source => ({ value: source.id, label: source.name }));
    if (this.rawDataSourceOptions.length) {
      this.indicatorFormGroup?.controls.rawDataSources.enable();
    }
  }

  async getCompatibleIndicatorDefinitionDataSources(indicatorDefinitionId: string) {
    const response =
      await this.indicatorDataSourceClientApiService.getIndicatorDefinitionDataSourcesCompatibleWithIndicatorDefinition(
        indicatorDefinitionId
      );
    this.indicatorDefinitionDataSourceOptions = response.result.map(source => ({
      value: source.id,
      label: source.name,
    }));
    if (this.indicatorDefinitionDataSourceOptions.length) {
      this.indicatorFormGroup?.controls.indicatorDefinitionDataSources.enable();
    }
  }

  async updateIndicator() {
    const category = Object.values(IndicatorCategory).find(type => type === this.indicatorForm?.category.value.value);
    if (
      this.indicatorDefinition &&
      this.indicatorFormGroup &&
      this.indicatorFormGroup.valid &&
      this.indicatorFormGroup.dirty &&
      category
    ) {
      this.indicatorFormGroup.markAsPristine();
      const valueType = this.indicatorFormGroup.controls.valueType.value.value as IndicatorValueType;
      let oganizationAccessIds: string[] | undefined = undefined;

      switch (this.indicatorFormGroup.controls.accessiblity.value.value) {
        case AccessibilityTypes.Global:
          oganizationAccessIds = [Constants.GlobalKey];
          break;
        case AccessibilityTypes.Custom:
          oganizationAccessIds = this.indicatorFormGroup.controls.accesibilityCustomOrganiations.value.map(
            o => o.value
          );
          if (oganizationAccessIds.length === 0) {
            oganizationAccessIds = undefined;
          }
          break;
      }
      const response = await this.indicatorDefinitionsApiService.updateIndicatorDefinition(
        new UpdateIndicatorDefinitionCommandParams({
          id: this.indicatorDefinition.id,
          name: this.indicatorDefinition.name,
          description: this.indicatorFormGroup.controls.description.value,
          frameworkStandards: this.indicatorFormGroup.controls.referenceStandars.value,
          category: category,
          sectors: this.indicatorFormGroup.controls.sectors.value.map(s => s.value as SectorType),
          numericValueDefinition:
            valueType === IndicatorValueType.Table
              ? this.indicatorDefinition.numericValueDefinition
              : new IndicatorNumericValueDefinition({
                  unit: this.indicatorFormGroup.controls.unit.value?.value || '',
                  unitLabel: this.indicatorFormGroup.controls.unitLabel.value,
                  calculationType: (this.indicatorFormGroup.controls.calculationType.value?.value ||
                    CalculationType.Average) as CalculationType,
                }),
          tableValueDefinition:
            valueType === IndicatorValueType.Table
              ? this.indicatorFormGroup.controls.tableDefinition.value
              : this.indicatorDefinition.tableValueDefinition,
          indicatorRawDataSourceIds: this.indicatorFormGroup.controls.rawDataSources.value.map(s => s.value),
          indicatorDefinitionAsDataSourceIds: this.indicatorFormGroup.controls.indicatorDefinitionDataSources.value.map(
            s => s.value
          ),
          organizationAccessIds: oganizationAccessIds,
          valueType: valueType,
          recordDuration: this.indicatorFormGroup.controls.recordDuration.value.value as RecordDuration,
        })
      );
      if (response.validationErrors) {
        this.notificationService.showError(response.validationErrors.join('\n'));
      } else {
        this.closeTableDialog();
      }
    }
  }

  async updateAndResetSources() {
    this.indicatorForm?.rawDataSources.setValue([]);
    this.indicatorForm?.indicatorDefinitionDataSources.setValue([]);
    this.indicatorForm?.rawDataSources.disable();
    this.indicatorForm?.indicatorDefinitionDataSources.disable();
    await this.updateIndicator();
    if (this.indicatorDefinition?.id) {
      await Promise.all([
        this.getCompatibleRawDataSources(this.indicatorDefinition.id),
        this.getCompatibleIndicatorDefinitionDataSources(this.indicatorDefinition.id),
      ]);
    }
  }

  handleValueTypeChange(option: IOption) {
    if (!this.indicatorForm || this.indicatorForm.valueType.value === option) return;

    this.indicatorForm.valueType.setValue(option);
    this.indicatorForm.valueType.markAsDirty();

    if (option.value === IndicatorValueType.Table) {
      if (!this.indicatorForm.tableDefinition.value) {
        this.indicatorForm.tableDefinition.setValue(defaultStaticTable);
      }
      this.indicatorForm.unit.disable();
      this.indicatorForm.unitLabel.disable();
      this.indicatorForm.calculationType.disable();
      this.indicatorForm.unit.setValue(undefined);
      this.indicatorForm.unitLabel.setValue('');
      this.indicatorForm.calculationType.setValue(undefined);
    } else {
      this.indicatorForm.unit.enable();
      this.indicatorForm.unitLabel.enable();
      this.indicatorForm.calculationType.enable();
      this.indicatorForm.unit.setValue(
        this.unitOptions.find(opt => opt.value === this.indicatorDefinition?.numericValueDefinition?.unit)
      );
      this.indicatorForm.unitLabel.setValue(this.indicatorDefinition?.numericValueDefinition?.unitLabel || '');
      this.indicatorForm.calculationType.setValue(
        this.calculationTypeOptions.find(
          opt => opt.value === this.indicatorDefinition?.numericValueDefinition?.calculationType
        )
      );
    }
    this.updateAndResetSources();
  }

  handleSelectUnit(unit: IOption) {
    if (this.indicatorForm && this.indicatorForm.unit.value !== unit) {
      this.indicatorForm.unit.setValue(unit);
      this.indicatorForm.unit.markAsDirty();
      this.indicatorForm.unitLabel.setValue(unit.label);
      this.indicatorForm.unitLabel.markAsDirty();
      this.updateAndResetSources();
    }
  }

  handleSelectCalculationType(option: IOption) {
    if (this.indicatorForm && this.indicatorForm.calculationType.value !== option) {
      this.indicatorForm.calculationType.setValue(option);
      this.indicatorForm.calculationType.markAsDirty();
      this.updateAndResetSources();
    }
  }

  handleTableDefinitionChange(tableDefinition: IndicatorTableValueDefinition) {
    if (this.indicatorForm) {
      this.indicatorForm.tableDefinition.setValue(tableDefinition);
      this.indicatorForm.tableDefinition.markAsDirty();
      this.updateAndResetSources();
    }
  }

  handleDropdownChange(formControl: FormControl<IOption | undefined>, option: IOption) {
    if (option !== formControl.value) {
      formControl.setValue(option);
      formControl.markAsDirty();
      this.updateIndicator();
    }
  }

  openTableDialog() {
    this.isTableDialogOpen = true;
  }

  closeTableDialog() {
    this.isTableDialogOpen = false;
  }

  ngOnDestroy() {
    this.indicatorAccessibilitySubscription?.unsubscribe();
    this.unitOptionsSubscription?.unsubscribe();
    this.sectorsSubscription?.unsubscribe();
    this.rawSourcesSubscription?.unsubscribe();
    this.indicatorSourcesSubscription?.unsubscribe();
  }
}
